export function pushGA4Event(event: any) {
  let w = window as any;

  w.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  w.dataLayer.push(event);
}

export function gtag(...args: any[]) {
  let w = window as any;

  w.dataLayer.push(args);
}
