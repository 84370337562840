export function enableEnsighten() {
  if ((window as any).RSWeb) (window as any).RSWeb.EnableEnsighten = rsWebEnableEnsighten;
  else (window as any).RSWeb = { EnableEnsighten: rsWebEnableEnsighten };
}

function rsWebEnableEnsighten() {
  (window as any).RSWeb.Ensighten = { CookiesEnabled: true };

  setInterval(function () {
    if (document.getElementById('ensModalWrapper')) {
      const observer = new MutationObserver(showOverlay),
        ensModal: any = document.querySelector('#ensModalWrapper'),
        objConfig = {
          childList: true,
          subtree: true,
          attributes: true,
          characterData: false,
        };

      observer.observe(ensModal, objConfig);
    }
  }, 100);
}

function showOverlay() {
  if (document.getElementById('ensModalWrapper')) {
    //Inject grey background behind modal
    if (!document.getElementById('ensModalBG')) {
      var backOverlay = document.createElement('div');
      backOverlay.id = 'ensModalBG';
      document.body.appendChild(backOverlay);
    }

    let ensModalBG = document.querySelector<HTMLElement>('#ensModalBG');

    if (document.querySelector<HTMLElement>('#ensModalWrapper')?.style.display === 'block') {
      if (ensModalBG) ensModalBG.style.display = 'block';
    } else if (document.querySelector<HTMLElement>('#ensModalWrapper')?.style.display !== 'block') {
      if (ensModalBG) ensModalBG.style.display = 'none';
    }
  }
}

export function pushEvent(eventKey: string, eventData: any) {
  let w = window as any;

  try {
    if (typeof w.rs !== 'undefined' && typeof w.rs.web !== 'undefined' && typeof w.rs.web.digitalData !== 'undefined') {
      w.rs.web.digitalData.events = w.rs.web.digitalData.events || [];
      w.rs.web.digitalData.events.push({
        eventKey: eventKey,
        eventData: eventData,
        eventTimestamp: new Date().toUTCString(),
      });
      w.Bootstrapper.ensEvent.trigger('customPageDataEvent');
      console.log('Pushed event ' + eventKey + ' with data ' + JSON.stringify(eventData));
    } else {
      console.log('rs.web.digitalData not available');
    }
  } catch (ex) {
    console.error(ex);
  }
}

// https://rs-components.atlassian.net/wiki/spaces/MAR/pages/4168876052/Click+counter
export function clickEvent(eventDataAttributes: any) {
  const eventKey = 'rsDeliversClickTracker';
  const eventData = {
    category: {
      primaryCategory: 'clickEvent',
    },
    attributes: {
      __Type: 'buttonTracker',
      ...eventDataAttributes,
    },
  };

  let w = window as any;

  try {
    w.Bootstrapper.ensEvent.trigger(eventKey, eventData);
    console.log('Pushed click event ' + eventKey + ' with data ' + JSON.stringify(eventData));
  } catch (ex) {
    console.error(ex);
  }
}

export function clearDigitalData() {
  if (typeof window !== 'undefined') {
    let w = window as any;

    if (typeof w.rs !== 'undefined' && typeof w.rs.web !== 'undefined' && typeof w.rs.web.digitalData !== 'undefined') {
      w.rs.web.digitalData = null;
    }
  }
}
