import { ICategoryListPage } from '../../../models/clp/category-list-page.model';
import { ISiteSettingsCurrency } from '../../../models/master/site-settings-currency.model';
import { ISiteSettingsLanguage } from '../../../models/master/site-settings-language.model';
import { IMenu } from '../../../models/menu/menu.model';
import { IMenuSection } from './product-menu.vm';

export type State = {
  /*{
      0: boolean; //default
      1: boolean; //category (mobile/tablet specific)
      2: boolean; //level2
      3: boolean; //level3 (mobile/tablet specific)
    };*/
  levelVisibility: {
    [key: string]: boolean;
  };
  isOpen: boolean;
  level: number;
  currentLevel2Category?: number;
  currentCategory?: string | number;
  innerMenuData?: IMenu;
  categoryPageData?: ICategoryListPage;
  currentLevel3Category?: string;
  preRenderedLevels: {
    level1M: IMenuSection[];
    level1D: IMenuSection[];
  };
  defaultLang: ISiteSettingsLanguage;
  defaultCurrency: ISiteSettingsCurrency;
  selectedLang?: ISiteSettingsLanguage;
  selectedCurrency?: ISiteSettingsCurrency;
  isGoingBackToLevel3?: boolean;
};

export type MenuNavigationAction =
  | { type: 'down'; level: number; categoryId?: string | number }
  | { type: 'up'; level: number; categoryId?: string | number };
export type MenuRequestAction =
  | { type: 'get'; categoryId: number; level: number; clickData?: any }
  | { type: 'getCategories'; url: string; level3CategoryId: string }
  | { type: 'getBrands' }
  | { type: 'showCurrencies' }
  | { type: 'showLanguages' }
  | { type: 'set'; data?: IMenu; categoryPage?: ICategoryListPage }
  | { type: 'setSelectedLang'; data: ISiteSettingsLanguage }
  | { type: 'setDefaultSiteSettings'; currency?: ISiteSettingsCurrency; language?: ISiteSettingsLanguage }
  | { type: 'setSelectedCurrency'; data: ISiteSettingsCurrency }
  | { type: 'goBackToLevel3' };
export type Action = { type: 'show'; open: boolean } | MenuNavigationAction | MenuRequestAction;

export enum MenuType {
  Level = 'Level',
  Categories = 'Categories',
  Brands = 'Brands',
  Currencies = 'Currencies',
  Languages = 'Languages',
}
