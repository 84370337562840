import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Card, Pill } from 'rs-emd-ui-atoms';
import { getLabel } from '../../../../helpers/html.utils';
import { ILabel } from '../../../../models/common/label.model';
import { StockStatus } from '../../../../models/common/stock.model';
import { IProductDetailPage, ISuggestedProduct } from '../../../../models/master/header/predictive-search.model';
import styles from './product-search-component.module.scss';

type ProductSearchProps = {
  // config: IProductSearchConfig;
  labels?: ILabel[];
  product: ISuggestedProduct;
  productDetailPage?: IProductDetailPage;
  isAlternative: boolean;
  incVat: boolean;
  onClick: (e: any) => void;
};

// type State = {
//   isStockLoading: boolean;
//   stock?: IStock | null;
// };

// type Action = { type: ProductSearchActionType.IsLoadingStock } | { type: ProductSearchActionType.SetStockData; stock?: IStock | null };

// export enum ProductSearchActionType {
//   IsLoadingStock = 'IsLoadingStock',
//   SetStockData = 'SetStockData',
// }

export const ProductSearch: FunctionComponent<ProductSearchProps> = (props: ProductSearchProps) => {
  // const [state, dispatch] = useReducer(reducer, {
  //   isStockLoading: false,
  // });
  ProductSearch.defaultProps = {
    labels: [],
  };

  let labels = [...(props.labels || []), ...(props.productDetailPage?.labels || [])];

  let discount = props.productDetailPage?.priceArea?.price?.priceAfter?.value !==
    props.productDetailPage?.priceArea?.price?.priceBefore?.value && (
    <p className={styles.discount}>{props.productDetailPage?.priceArea?.price?.priceBefore?.displayValue}</p>
  );

  return (
    <Card className={`${styles['product-search-container']} ${props.isAlternative ? styles['alterantive'] : ''}`}>
      <div className={styles['product-search']}>
        <div className={styles.info}>
          <Link to={props.product.pdpUrl} className={`${styles['thumb-container']} ${styles.lg}`} onClick={props.onClick}>
            <img className={styles.thumb} src={props.product.primaryImageUrl} alt={props.product.title}></img>
          </Link>

          <div className={styles.details}>
            <div className={styles['title-area']}>
              {/* mobile and tablet thumbnail */}
              <Link to={props.product.pdpUrl} className={`${styles['thumb-container']} ${styles.sm}`} onClick={props.onClick}>
                <img className={styles.thumb} src={props.product.primaryImageUrl} alt={props.product.title}></img>
              </Link>
              <Link to={props.product.pdpUrl} className={`snippet ${styles.title}`} onClick={props.onClick}>
                {props.product.title}
              </Link>
            </div>

            {/* PPK */}
            {props.productDetailPage?.ppkArea && props.productDetailPage?.ppkArea.isVisible && props.product.stockCode.substr(-1) !== 'P' && (
              <Link
                to={props.product.pdpUrl.replace('/' + props.product.stockCode, '/' + props.productDetailPage.ppkArea.ppkStockCode)}
                onClick={props.onClick}>
                {getLabel(props.labels, 'also_available_in_production_pack')}
              </Link>
            )}

            {/* Attributes */}
            <div className={styles.attributes}>
              {props.product.formattedStockCode && (
                <span>
                  <b>{getLabel(labels, 'stock_code')}</b>:&nbsp;
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.product.formattedStockCode.replace(
                        props.product.highlightedFragment,
                        `<b>${props.product.highlightedFragment}</b>`
                      ),
                    }}
                  />
                </span>
              )}
              {props.product.brand && (
                <span>
                  <b>{getLabel(labels, 'brand')}</b>: {props.product.brand}
                </span>
              )}
              {props.product.mpn && (
                <span>
                  <b>{getLabel(labels, 'mpn')}</b>:&nbsp;
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.product.mpn.replace(props.product.highlightedFragment, `<b>${props.product.highlightedFragment}</b>`),
                    }}
                  />
                </span>
              )}
              {props.productDetailPage?.titleArea?.impaCode && props.productDetailPage?.titleArea?.impaCode.length > 0 && (
                <span>
                  <b>{getLabel(labels, 'impa')}</b>: {props.productDetailPage?.titleArea?.impaCode}
                </span>
              )}
              {props.productDetailPage?.titleArea?.hsnCode && (
                <span>
                  <b>{getLabel(labels, 'hsn')}</b>: {props.productDetailPage?.titleArea?.hsnCode}
                </span>
              )}
            </div>
          </div>
        </div>
        {props.productDetailPage?.stockArea?.statusCode === StockStatus.Discontinued ? (
          <div className={styles['error-pill-container']}>
            <Pill class={styles['error-pill']}>{props.productDetailPage?.stockArea.title}</Pill>
          </div>
        ) : (
          <div className={`${styles.block} ${styles['price-block']}`}>
            {/* {props.config.showYourPrice && <p className={styles['your-price']}>{getLabel(labels, 'your_price')}</p>} */}
            {getPriceLine()}
            {/*
            //-- STOCK CHECK: START --//
            <div className={styles['stock-check']}>
               {!state.stock && !state.isStockLoading && (
                  <p className='link' onClick={getStockData}>
                    {getLabel(labels, 'check_stock')}
                  </p>
                )}
                {state.isStockLoading && (
                  <div className={styles.loading}>
                    <CircularLoader width={16}></CircularLoader>
                    <p className={`link ${styles['stock-check']}`}>{getLabel(labels, 'loading_stock')}</p>
                  </div>
                )}
              {state.stock && <Pill class={`stock ${state.stock?.statusCode.toLowerCase() ?? ''}`}>{state.stock?.title}</Pill>}
              //-- STOCK CHECK: END --//
              //-- DEFAULT STOCK INFO --//
              {props.productDetailPage?.stockArea && (
                <Pill class={`stock ${props.productDetailPage?.stockArea.statusCode.toLowerCase() ?? ''}`}>
                  {props.productDetailPage?.stockArea.title}
                </Pill>
              )}
            </div> */}
          </div>
        )}
      </div>
    </Card>
  );

  // function reducer(state: State, action: Action): State {
  //   switch (action.type) {
  //     case ProductSearchActionType.IsLoadingStock: {
  //       return {
  //         ...state,
  //         isStockLoading: true,
  //       };
  //     }
  //     case ProductSearchActionType.SetStockData: {
  //       return {
  //         ...state,
  //         isStockLoading: false,
  //         stock: action.stock,
  //       };
  //     }
  //   }
  // }

  function getPriceLine() {
    return (
      <div className={styles['price-line']}>
        <div className={styles.price}>
          <p className={styles['unit-price']}>{props.productDetailPage?.priceArea?.price?.priceAfter?.displayValue}</p>
        </div>
        {/* hide extra info if POA */}

        {props.productDetailPage?.priceArea?.price?.priceAfter?.value !== 0 && (
          <p className={styles.container}>
            {discount}
            {props.productDetailPage?.priceArea?.container} {getLabel(props.labels, props.incVat ? 'inc_vat_brackets' : 'ex_vat_brackets')}
          </p>
        )}
      </div>
    );
  }

  // function getStockData() {
  //   dispatch({ type: ProductSearchActionType.IsLoadingStock });
  //   props.stockService.getStockData({ stockCode: props.product.title.stockCode }).then((data) => {
  //     dispatch({ type: ProductSearchActionType.SetStockData, stock: data.stockArea });
  //   });
  // }
};
