import {
  GetPricesByQtyDocument,
  GetPricesByQtyQuery,
  GetPricesByQtyQueryVariables,
  GetPricesDocument,
  GetPricesQuery,
  GetPricesQueryVariables,
} from '../generated/graphql';
import { IPricingService } from '../rs-emd-ui-modules/src/services/pricing/pricing-service';
import {
  IPricesRequest,
  IPricesResult,
  IPricesByQtyRequest,
  IPricesByQtyResult,
} from '../rs-emd-ui-modules/src/services/pricing/pricing.dto';
import { graphQLService } from './graphql-service';

export class PricingService implements IPricingService {
  async getPrices(variables: IPricesRequest): Promise<IPricesResult> {
    const result = await graphQLService.apolloClient.query<GetPricesQuery, GetPricesQueryVariables>({
      query: GetPricesDocument,
      variables: { stockCode: variables.stockCode },
    });

    let res: IPricesResult = {};
    if (result.data) {
      res.productPrices = result.data.productListPage.productPrices;
    }
    return res;
  }

  async getPricesByQty(variables: IPricesByQtyRequest): Promise<IPricesByQtyResult> {
    const result = await graphQLService.apolloClient.query<GetPricesByQtyQuery, GetPricesByQtyQueryVariables>({
      query: GetPricesByQtyDocument,
      variables: { stockCode: variables.stockCode, quantity: variables.quantity },
      fetchPolicy: 'no-cache',
    });

    let res: IPricesByQtyResult = {};
    if (result.data) {
      res.priceArea = result.data.productDetailPage.priceArea;
    }
    return res;
  }
}

export const pricingService = new PricingService();
