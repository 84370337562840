import {
  AddRecentSearchDocument,
  AddRecentSearchMutation,
  AddRecentSearchMutationVariables,
  GetRecentSearchesDocument,
  GetRecentSearchesQuery,
  GetRecentSearchesQueryVariables,
  GetSuggestedSearchesDocument,
  GetSuggestedSearchesQuery,
  GetSuggestedSearchesQueryVariables,
  ProductDetailPagesDocument,
  ProductDetailPagesQuery,
  ProductDetailPagesQueryVariables,
  RemoveAllRecentSearchesDocument,
  RemoveAllRecentSearchesMutation,
  RemoveAllRecentSearchesMutationVariables,
  RemoveRecentSearchDocument,
  RemoveRecentSearchMutation,
  RemoveRecentSearchMutationVariables,
} from '../generated/graphql';
import { setCookie } from '../rs-emd-ui-modules/src';
import { IPredictiveSearchService } from '../rs-emd-ui-modules/src/services/predictive-search/predictive-search-service';
import {
  IAddRecentSearchRequest,
  IAddRecentSearchResponse,
  IGetRecentSearchesResponse,
  IGetSuggestedSearchesRequest,
  IGetSuggestedSearchesResponse,
  IProductDetailPagesRequest,
  IProductDetailPagesResponse,
  IRemoveRecentSearchRequest,
} from '../rs-emd-ui-modules/src/services/predictive-search/predictive-search.dto';
import { clickEvent } from '../tagging/ensighten';
import { graphQLService } from './graphql-service';

export class PredictiveSearchService implements IPredictiveSearchService {
  search(data: IAddRecentSearchRequest) {
    if (data.variables.searchQuery) {
      setCookie('SearchTermTyped', encodeURI(data.variables.searchQuery));
      this.addRecentSearch(data).then(() => {
        var familyQuery = '';

        // from clp?
        if (data.variables.familyId && data.variables.trackingResult) {
          const eventData = {
            __Type: 'buttonTracker',
            __Info: 'Search within category',
            __Result: data.variables.trackingResult,
          };

          clickEvent(eventData);
          familyQuery = `&familyId=${data.variables.familyId}`;
        }

        window.location.href = `/productlist/search?query=${data.variables.searchQuery}${familyQuery}`;
      });
    }
  }

  async addRecentSearch(data: { variables: AddRecentSearchMutationVariables }): Promise<IAddRecentSearchResponse> {
    const res = await graphQLService.apolloClient.mutate<AddRecentSearchMutation, AddRecentSearchMutationVariables>({
      mutation: AddRecentSearchDocument,
      variables: data.variables,
    });

    let result: IAddRecentSearchResponse = { isSuccess: false };

    if (res.data) {
      result.isSuccess = res.data.addRecentSearch.isSuccess;
    }

    return result;
  }

  removeRecentSearch(data: IRemoveRecentSearchRequest): void {
    graphQLService.apolloClient.mutate<RemoveRecentSearchMutation, RemoveRecentSearchMutationVariables>({
      mutation: RemoveRecentSearchDocument,
      variables: data.variables,
    });
  }

  removeAllRecentSearches(): void {
    graphQLService.apolloClient.mutate<RemoveAllRecentSearchesMutation, RemoveAllRecentSearchesMutationVariables>({
      mutation: RemoveAllRecentSearchesDocument,
    });
  }

  async getRecentSearches(): Promise<IGetRecentSearchesResponse> {
    var result = await graphQLService.apolloClient.query<GetRecentSearchesQuery, GetRecentSearchesQueryVariables>({
      query: GetRecentSearchesDocument,
      fetchPolicy: 'no-cache',
    });

    let res: IGetRecentSearchesResponse = {};

    if (result.data) {
      res.getRecentSearches = result.data.getRecentSearches;
    }

    return res;
  }

  async getSuggestedSearches(data: IGetSuggestedSearchesRequest): Promise<IGetSuggestedSearchesResponse> {
    var result = await graphQLService.apolloClient.query<GetSuggestedSearchesQuery, GetSuggestedSearchesQueryVariables>({
      query: GetSuggestedSearchesDocument,
      variables: data.variables,
    });

    let res: IGetSuggestedSearchesResponse = {};

    if (result.data) {
      res.getSuggestedSearches = result.data.getSuggestedSearches;
    }
    return res;
  }

  async productDetailPages(data: IProductDetailPagesRequest): Promise<IProductDetailPagesResponse> {
    var result = await graphQLService.apolloClient.query<ProductDetailPagesQuery, ProductDetailPagesQueryVariables>({
      query: ProductDetailPagesDocument,
      variables: data.variables,
    });

    let res: IProductDetailPagesResponse = {};

    if (result.data) {
      res.productDetailPages = result.data.productDetailPages;
    }
    return res;
  }
}

export const predictiveSearchService = new PredictiveSearchService();
