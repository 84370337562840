import { ApolloClient, ApolloLink, from, HttpLink, split } from '@apollo/client';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { fetch } from 'apollo-env';
import window from 'window-or-global';
import * as ws from 'ws';
import introspection from '../generated/possible-types';
import { CSRErrorLink, IsSuccessLogging } from '../graphql/error-link';

export class GraphQLService {
  private ignoredOpErrors = (process.env.REACT_APP_GRAPHQL_IGNORED_ERRORS ?? '').split('|');

  public apolloClient: ApolloClient<NormalizedCacheObject>;
  public isBot: boolean = false;

  constructor() {
    let apolloState = (window as any).__APOLLO_STATE__ ?? '';
    this.apolloClient = new ApolloClient({
      link: from([CSRErrorLink, IsSuccessLogging, this.retryLink, (process as any).browser ? this.splitLink() : this.getHttpLink()]),
      cache: new InMemoryCache({
        possibleTypes: introspection.possibleTypes,
        typePolicies: {
          Master: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      }).restore(JSON.parse(JSON.stringify(apolloState))),
    });
  }

  private authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    // any headers which may change between calls should be set here
    let _headers = {
      ...headers,
      origin: window.location?.origin,
      'x-cookie': process.env.REACT_APP_X_COOKIE ? process.env.REACT_APP_X_COOKIE : window.document?.cookie, // use env file to set x-cookie
      'x-referer': window.location?.href,
    };

    if (process.env.REACT_APP_ORIGINAL_SITE) _headers['original-site'] = process.env.REACT_APP_ORIGINAL_SITE;

    return {
      headers: {
        ..._headers,
      },
    };
  });

  /**
   * Apollo retry link : https://www.apollographql.com/docs/react/api/link/apollo-link-retry/#default-configuration
   */
  private retryLink = new RetryLink({
    delay: {
      initial: 200,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 10,
      retryIf: (error, _operation) => !!error, // retry if error is populated
    },
  });

  private getStaticHeaders() {
    return {
      'x-renderer': 'csr',
    };
  }

  /**
   * Apollo HTTP Link
   */
  private getHttpLink() {
    return split(
      ({ operationName }) => {
        return this.isBot && this.ignoredOpErrors.includes(operationName);
      },
      ApolloLink.empty(), //if bot and async operation
      this.authLink.concat(
        new HttpLink({
          uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
          fetch: fetch as any,
          credentials: 'include',
          headers: this.getStaticHeaders(),
        })
      ) //all other cases
    );
  }

  /**
   * Apollo WS Link - used for subscribtions
   */
  private getWSLink() {
    let uri = process.env.REACT_APP_GRAPHQL_WS ?? process.env.REACT_APP_GRAPHQL_ENDPOINT ?? '';

    // relative uri?
    if (uri.indexOf('://') < 0) {
      uri = window.location.origin + uri;
    }
    uri = uri.replace('http', 'ws');

    let websocketConfig: WebSocketLink.Configuration = {
      uri: uri,
      options: {
        reconnect: true,
        lazy: true,
        connectionParams: {
          credentials: 'include',
          headers: this.getStaticHeaders(),
        },
      },
    };

    // required for ssr
    if (!(process as any).browser) websocketConfig.webSocketImpl = ws;

    return new WebSocketLink(websocketConfig);
  }

  private splitLink() {
    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value

    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      this.authLink.concat(this.getWSLink()), //if subscription
      this.authLink.concat(this.getHttpLink()) //if query/mutation
    );
  }
}

export const graphQLService = new GraphQLService();
