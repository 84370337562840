import { sanitizeUrl } from '@braintree/sanitize-url';
import { ILabel } from '../models/common/label.model';
import { ILinkAttribute } from '../models/common/link-attribute.model';
import { IHtmlTagAttribute } from '../models/common/metadata-tagging/html-tag-attribute.model';

export function getLabel(labels: ILabel[] | undefined | null, labelName: string): string {
  let matchingLabels = labels?.filter((l) => l.name === labelName)[0];
  return matchingLabels ? matchingLabels.text ?? '' : labelName;
}

export function getAttributes(attributeList: ILinkAttribute[] | undefined): {} {
  var attributes: any = {};
  attributeList?.forEach((attribute) => {
    attributes[attribute.name] = attribute.value;
  });

  return attributes;
}

export function getTagAttributes(attributeList: IHtmlTagAttribute[] | undefined): {} {
  var attributes: any = {};
  attributeList?.forEach((attribute) => {
    attributes[attribute.name] = attribute.value;
  });

  return attributes;
}

export function setCookie(cname: string, cvalue: string, expiryDays?: number, secure?: boolean) {
  var expires: string = '';
  if (expiryDays) {
    var d = new Date();
    d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
    expires = 'expires=' + d.toUTCString();
  }
  document.cookie = `${cname}=${cvalue};${expires};path=/;${secure ? 'secure;' : ''}`;
}

export function getCookie(cname: string): string | undefined {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

function isUrlRelative(url: string) {
  try {
    new URL(url);
    return false;
  } catch (_) {
    return true;
  }
}

export function sanitizeRelativeUrl(url: string): string {
  const sanitizedUrl = sanitizeUrl(url);
  return sanitizedUrl === 'about:blank' ? '' : isUrlRelative(sanitizedUrl) ? sanitizedUrl : '';
}
