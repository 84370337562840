import {
  ResetPasswordDocument,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  ResetPasswordTransactionDocument,
  ResetPasswordTransactionMutation,
  ResetPasswordTransactionMutationVariables,
  SaveCustomerDocument,
  SaveCustomerMutation,
  SaveCustomerMutationVariables,
  UpdateCustomerDocument,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
  ValidateEmailDocument,
  ValidateEmailQuery,
  ValidateEmailQueryVariables,
} from '../generated/graphql';
import { ICustomerService } from '../rs-emd-ui-modules/src/services/customer/customer-service';
import {
  IResetPasswordRequest,
  IResetPasswordResult,
  IResetPasswordTransactionRequest,
  IResetPasswordTransactionResult,
  ISaveCustomerRequest,
  ISaveCustomerResult,
  IUpdateCustomerRequest,
  IUpdateCustomerResult,
  IValidateEmailRequest,
  IValidateEmailResult,
} from '../rs-emd-ui-modules/src/services/customer/customer.dto';
import { pushGA4Event } from '../tagging/ga4';
import { graphQLService } from './graphql-service';

export class CustomerService implements ICustomerService {
  async validateEmail(input: IValidateEmailRequest): Promise<IValidateEmailResult> {
    const result = await graphQLService.apolloClient.query<ValidateEmailQuery, ValidateEmailQueryVariables>({
      query: ValidateEmailDocument,
      variables: { input: input },
      fetchPolicy: 'no-cache',
    });

    let res: IValidateEmailResult = {};

    if (result && result.data) {
      res.data = result.data.validateEmail;
    }

    return res;
  }

  async saveCustomer(input: ISaveCustomerRequest): Promise<ISaveCustomerResult> {
    const result = await graphQLService.apolloClient.mutate<SaveCustomerMutation, SaveCustomerMutationVariables>({
      mutation: SaveCustomerDocument,
      variables: { input: input },
    });

    let res: ISaveCustomerResult = {};

    if (result.data) {
      res.data = result.data.saveCustomer;

      if (result.data.saveCustomer.isSuccess) {
        pushGA4Event({
          event: 'user_registration',
          ecommerce: {
            email: input.customer.email,
          },
        });
      }
    }

    return res;
  }

  async resetPasswordTransaction(input: IResetPasswordTransactionRequest): Promise<IResetPasswordTransactionResult> {
    const result = await graphQLService.apolloClient.mutate<ResetPasswordTransactionMutation, ResetPasswordTransactionMutationVariables>({
      mutation: ResetPasswordTransactionDocument,
      variables: { input: input },
    });

    let res: IResetPasswordTransactionResult = {};

    if (result.data) {
      res.data = result.data.resetPasswordTransaction;
    }

    return res;
  }

  async resetPassword(input: IResetPasswordRequest): Promise<IResetPasswordResult> {
    const result = await graphQLService.apolloClient.mutate<ResetPasswordMutation, ResetPasswordMutationVariables>({
      mutation: ResetPasswordDocument,
      variables: { input: input },
    });

    let res: IResetPasswordResult = {};

    if (result.data) {
      res.data = result.data.resetPassword;
    }

    return res;
  }

  async updateCustomer(req: IUpdateCustomerRequest): Promise<IUpdateCustomerResult> {
    const result = await graphQLService.apolloClient.mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
      mutation: UpdateCustomerDocument,
      variables: { input: req.input },
    });

    let res: IUpdateCustomerResult = {};

    if (result.data) {
      res.data = result.data.updateCustomer;
    }

    return res;
  }
}

export const customerService = new CustomerService();
