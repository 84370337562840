import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IContextInfo } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import BasketWrapper from '../basket-purchase/basket-wrapper-component';
import OrderConfirmationWrapper from '../basket-purchase/order-confirmation-wrapper-component';
import PurchaseWrapper from '../basket-purchase/purchase-wrapper-component';
import CampaignPageWrapper from '../campaigns/campaign-page/campaign-page-wrapper-component';
import CLPWrapper from '../clp/clp-wrapper-component';
import CompareAlternativesWrapper from '../compare-alternatives/compare-alternatives-wrapper-component';
import Error404PageWrapper from '../error-pages/error-404-page-wrapper-component';
import TechnicalErrorWrapper from '../error-pages/technical-error-wrapper-component';
import PDPWrapper from '../pdp/pdp-wrapper-component';
import NoResultsWrapper from '../plp/no-results-wrapper-component';
import PLPWrapper from '../plp/plp-wrapper-component';
import SRPWrapper from '../srp/srp-wrapper-component';
import ForgotPasswordWrapper from '../user/forgot-password-wrapper-component';
import LoginWrapper from '../user/login-wrapper-component';
import MyAccountWrapper from '../user/my-account/my-account-wrapper-component';
import RegistrationWrapper from '../user/registration-wrapper-component';
import ResetPasswordWrapper from '../user/reset-password-wrapper-component';

type RouterProps = {
  contextInfo: IContextInfo;
};

export const Router: FunctionComponent<RouterProps> = (props: RouterProps) => {
  return (
    <Switch>
      <Route path='(.*)/user/login' exact render={() => <LoginWrapper />}></Route>

      <Route path='(.*)/user/forgotPassword' exact render={() => <ForgotPasswordWrapper />}></Route>

      <Route path='(.*)/user/resetPassword' exact render={() => <ResetPasswordWrapper />}></Route>

      <Route path='(.*)/user/register' exact render={() => <RegistrationWrapper contextInfo={props.contextInfo} />}></Route>
      <Route
        path='(.*)/user/onboarding/register'
        exact
        render={() => <RegistrationWrapper contextInfo={props.contextInfo} onboarding={true} />}></Route>

      <Route path={'(.*)/user/(.*)'} render={() => <MyAccountWrapper contextInfo={props.contextInfo} />}></Route>

      <Route
        path='(.*)/cart'
        render={(routeProps) => <BasketWrapper contextInfo={props.contextInfo} key={routeProps.location?.key} />}></Route>

      <Route path='(.*)/purchase'>
        <PurchaseWrapper contextInfo={props.contextInfo} />
      </Route>

      <Route path='(.*)/confirmation'>
        <OrderConfirmationWrapper contextInfo={props.contextInfo} />
      </Route>

      <Route path='(.*)/category/(.+)' render={() => <CLPWrapper contextInfo={props.contextInfo} />} />

      <Route
        path='(.*)/product/(.*)/:stockCode'
        render={(routeProps) => <PDPWrapper key={routeProps.match.params.stockCode} contextInfo={props.contextInfo} />}
      />

      <Route path='(.*)/browse/(.*?)/:categoryId' render={() => <PLPWrapper contextInfo={props.contextInfo} />} />

      <Route
        path='(.*)/productlist/search'
        render={(routeProps) => <SRPWrapper key={routeProps.location.search} contextInfo={props.contextInfo} />}
      />

      <Route path='(.*)/compare-alternatives/:stockCode'>
        <CompareAlternativesWrapper />
      </Route>

      {/* Will check if any campaign pages satisfy the URL.*/}
      <Route
        path='(.*)/(content|ourbrands)/(.*)'
        render={({ staticContext }) => {
          return <CampaignPageWrapper context={staticContext} />;
        }}
      />

      {/* Error pages */}

      <Route path='/productpage/ProductNotFound'>
        <NoResultsWrapper />
      </Route>

      <Route path='/error404/error404'>
        <Error404PageWrapper />
      </Route>

      <Route path='/error/error'>
        <TechnicalErrorWrapper />
      </Route>

      {/* This route is needed to avoid hitting the 404 page during the healthcheck 
      - which would mark the node as unhealthy */}
      <Route
        exact
        path='(.*)/' // (.*) required for different base directories
        render={({ staticContext }) => {
          return <CampaignPageWrapper context={staticContext} />;
        }}
      />

      {/* If none of the previous routes render anything,
      this route acts as a fallback - shouldn't get to this point.

      Important: A route with path="/" will *always* match
      the URL because all URLs begin with a /. So that's
      why we put this one last of all */}

      <Route
        path='/'
        render={({ staticContext }) => {
          if (staticContext) staticContext.statusCode = 404;
          return <Redirect to='/error404/error404' />;
        }}
      />
    </Switch>
  );
};
