import { ReactText } from 'react';
import { ISiteSettingsCurrency } from '../models/master/site-settings-currency.model';
import { ISiteSettingsLanguage } from '../models/master/site-settings-language.model';
import { getLanguageCookie, getCurrencyCookie, getCurrencyShortName, setCurrencyCookie, setLanguageCookie } from './user-settings.utils';

export function setSiteLanguage(languages: ISiteSettingsLanguage[], key: ReactText) {
  let currentBaseDirectory = getLanguageCookie() ?? '';
  let baseDirectory = languages.find((l) => l.countryCodeISO2 === key)?.baseDirectory ?? '';

  if (currentBaseDirectory !== baseDirectory) {
    if (currentBaseDirectory) {
      currentBaseDirectory = `/${currentBaseDirectory}/`;
    }

    //set cookie
    if (baseDirectory) {
      setLanguageCookie(baseDirectory);
    } else {
      //delete cookie
      setLanguageCookie(baseDirectory, true);
    }

    baseDirectory = baseDirectory ? `/${baseDirectory}/` : '/';

    //redirect
    const currentLocation = window.location.href.replace(window.location.origin, '').replace(currentBaseDirectory, '');
    window.location.href = (baseDirectory + currentLocation).replace('//', '/');
  }
}

export function setSiteCurrency(currencies: ISiteSettingsCurrency[], key: ReactText) {
  let currency = currencies.find((c) => c.id === key);

  setCurrencyCookie(currency?.id.toString(), currency?.shortName);

  window.location.href = window.location.href;
}

export function getSiteCurrencyId(currencies: ISiteSettingsCurrency[]): number {
  // Default currency
  let selectedCurrencyId = getCurrencyCookie();
  let selectedCurrencyShortName = getCurrencyShortName();
  let selectedCurrency: ISiteSettingsCurrency | undefined = undefined;

  //check if selected currency exists in currencies
  if (selectedCurrencyId || selectedCurrencyShortName) {
    selectedCurrency = currencies.find((c) => c.id === Number(selectedCurrencyId) || c.shortName === selectedCurrencyShortName);
  }

  return selectedCurrency ? selectedCurrency.id : currencies[0].id;
}
