import loadable from '@loadable/component';

const AddressForm = loadable(() => import(/* webpackChunkName: "purchase" */ './address-form/address-form-component'), {
  resolveComponent: (components) => components.AddressForm,
});

const DeleteAddressModal = loadable(
  () => import(/* webpackChunkName: "purchase" */ './delete-address-modal/delete-address-modal-component'),
  {
    resolveComponent: (components) => components.DeleteAddressModal,
  }
);

const Purchase = loadable(() => import(/* webpackChunkName: "purchase" */ './purchase/purchase-component'), {
  resolveComponent: (components) => components.Purchase,
});

const PurchaseHeader = loadable(() => import(/* webpackChunkName: "purchase" */ './purchase-header/purchase-header-component'), {
  resolveComponent: (components) => components.PurchaseHeader,
});

const GSTDetails = loadable(() => import(/* webpackChunkName: "purchase" */ './GST-details/GST-details-component'), {
  resolveComponent: (components) => components.GSTDetails,
});

const InvoiceAddress = loadable(() => import(/* webpackChunkName: "purchase" */ './invoice-address/invoice-address-component'), {
  resolveComponent: (components) => components.InvoiceAddress,
});

const PersonalOrderReference = loadable(
  () => import(/* webpackChunkName: "purchase" */ './personal-order-reference/personal-order-reference-component'),
  {
    resolveComponent: (components) => components.PersonalOrderReference,
  }
);

const SavedAddressesModal = loadable(
  () => import(/* webpackChunkName: "purchase" */ './saved-addresses-modal/saved-addresses-modal-component'),
  {
    resolveComponent: (components) => components.SavedAddressesModal,
  }
);

const SelectPurchaser = loadable(() => import(/* webpackChunkName: "purchase" */ './select-purchaser/select-purchaser-component'), {
  resolveComponent: (components) => components.SelectPurchaser,
});

const ConfirmOrder = loadable(() => import(/* webpackChunkName: "purchase" */ './confirm-order/confirm-order-component'), {
  resolveComponent: (components) => components.ConfirmOrder,
});

export {
  AddressForm,
  DeleteAddressModal,
  Purchase,
  PurchaseHeader,
  GSTDetails,
  InvoiceAddress,
  PersonalOrderReference,
  SavedAddressesModal,
  SelectPurchaser,
  ConfirmOrder,
};
