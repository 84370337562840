import React, { FunctionComponent } from 'react';
import { Redirect, Route } from 'react-router-dom';

//https://reactrouter.com/web/guides/server-rendering/adding-app-specific-context-information

type RedirectWithStatusProps = {
  to?: Location | string;
  statusCode?: number;
};

export const RedirectWithStatus: FunctionComponent<RedirectWithStatusProps> = (props: RedirectWithStatusProps) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext && props.statusCode) staticContext.statusCode = props.statusCode;
        return <Redirect to={props.to ?? ''} />;
      }}
    />
  );
};
