import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { DocumentType, useDataSheetsQuery } from '../../generated/graphql';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';

type DocumentPageWrapperProps = {};

export const DocumentPageWrapper: FunctionComponent<DocumentPageWrapperProps> = (props: DocumentPageWrapperProps) => {
  let { type, stockCode } = useParams<{ type: string; stockCode: string }>();
  const supportedDocumentType = type === 'elr-information-sheet';

  const { loading, error, data } = useDataSheetsQuery({
    variables: { stockCode: stockCode, documentsType: '1' },
    ssr: false,
    skip: !supportedDocumentType,
  });

  if (supportedDocumentType) {
    if (loading) {
      return <Loader />;
    } else if (error || !data) {
      return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
    } else {
      const document = data.dataSheets?.find((d) => d.documentType === getDocumentType(type));
      if (document) {
        window.location.href = document.url;
        return <></>;
      } else return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={404} />;
    }
  } else return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={404} />;

  function getDocumentType(type: string): DocumentType | undefined {
    if (type === 'elr-information-sheet') return DocumentType.ElrProductInformationSheet;
    else return undefined;
  }
};

export default DocumentPageWrapper;
