import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useProductListPageQuery } from '../../generated/graphql';
import { ProductListPage } from '../../rs-emd-ui-modules/src/components/product-list-page';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import { basketService } from '../../services/basket-service';
import { partsListService } from '../../services/parts-list-service';
import { plpService } from '../../services/plp-service';
import { pricingService } from '../../services/pricing-service';
import { productService } from '../../services/product-service';
import { clearDigitalData } from '../../tagging/ensighten';
import NoResultsWrapper from '../plp/no-results-wrapper-component';

type SRPWrapperProps = {
  contextInfo: IContextInfo;
};

export const SRPWrapper: FunctionComponent<SRPWrapperProps> = (props: SRPWrapperProps) => {
  let location = useLocation();

  const searchQuery = new URLSearchParams(location.search).get('query');
  const tagName = new URLSearchParams(location.search).get('tag');
  const familyId = new URLSearchParams(location.search).get('familyId');
  const id = familyId ? +familyId : undefined;

  const tab = new URLSearchParams(location.search).get('tab');
  const isNoResultsPage = tab !== null && tab === 'zero';

  //b2tdvu
  const slugConstant = '/category/';
  const slug = location.pathname.startsWith(slugConstant)
    ? location.pathname.substring(location.pathname.lastIndexOf(slugConstant) + slugConstant.length)
    : undefined;

  const { loading, error, data } = useProductListPageQuery({
    variables: { searchQuery, id, tagName, slug },
    ssr: true,
    skip: isNoResultsPage,
  });

  //on no results page &tab=zero?
  if (isNoResultsPage) {
    return <NoResultsWrapper />;
  }
  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }
  //redirect?
  else if (data.productListPage.redirectUrl && data.productListPage.redirectUrl.indexOf('&tab=zero') === -1) {
    let redirectUrlParts = data.productListPage.redirectUrl.split('?');
    return (
      <RedirectWithStatus
        to={
          {
            pathname: redirectUrlParts[0],
            search: redirectUrlParts[1] ?? location.search,
          } as Location
        }
        statusCode={301}
      />
    );
  } else if (data.productListPage.redirectUrl) {
    return <RedirectWithStatus to={data.productListPage.redirectUrl} statusCode={301} />;
  } else if (!data.productListPage.isSuccess) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={404} />;
  } else if (data) clearDigitalData();

  return (
    <ProductListPage
      searchQuery={searchQuery}
      data={data.productListPage}
      contextInfo={props.contextInfo}
      basketService={basketService}
      plpService={plpService}
      pricingService={pricingService}
      productService={productService}
      partsListService={partsListService}
      locationHash={location.hash}></ProductListPage>
  );
};

export default SRPWrapper;
