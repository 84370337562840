
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CampaignPageWidgets": [
      "HeroBannerWidget",
      "ImageTextBoxWidget",
      "VideoDataWidget",
      "TextDataWidget",
      "ButtonDataWidget",
      "ImageDataWidget",
      "EmailFieldWidget",
      "PopularCategoriesWidget",
      "AllCategoriesWidget",
      "PreviouslyViewedProducts",
      "InPageAnchorWidget"
    ],
    "SectionContentLayoutDetails": [
      "ContentLayout",
      "SectionLayout"
    ],
    "DeliveryOptionProperties": [
      "DeliveryOptionCollectionProperties"
    ],
    "G4BasketEvent": [
      "GA4Event_ViewCart"
    ],
    "BulkAddToBasketResponseTags": [
      "BulkAddToBasketEnsighten",
      "BulkAddToBasketGoogleAnalytics",
      "BulkAddToBasketTealium",
      "GA4Event_AddToCart"
    ],
    "SetPromoCodeResponseTags": [
      "SetPromoCodeEnsighten",
      "SetPromoCodeGoogleAnalytics",
      "GA4Event_SelectPromotion"
    ],
    "RemoveFromBasketResponseTags": [
      "BulkRemoveFromBasketEnsighten",
      "BulkRemoveFromBasketGoogleAnalytics",
      "BulkRemoveFromBasketTealium",
      "GA4Event_RemoveFromCart",
      "GA4Event_UpdateBasketLines"
    ],
    "UpdateBasketLinesResponseTags": [
      "UpdateBasketLinesEnsighten",
      "UpdateBasketLinesGoogleAnalytics",
      "UpdateBasketLinesTealium",
      "GA4Event_UpdateBasketLines"
    ],
    "PurchasePagePaymentOptionTypes": [
      "PurchasePagePaymentOption_Account",
      "PurchasePagePaymentOption_ControlledPurchasing"
    ],
    "GA4PurchaseEvent": [
      "GA4Event_AddPaymentInfo"
    ],
    "PaymentOptionDetails": [
      "OrderConfirmationPage_BankTransferDetails",
      "OrderConfirmationPage_ControlledPurchasingDetails"
    ],
    "GA4OrderConfirmationEvent": [
      "GA4Event_Purchase"
    ],
    "LoginResponseTags": [
      "GA4Event_User_Login"
    ],
    "OrderDetailsPage_PaymentOptionDetails": [
      "OrderDetailsPage__PaymentOption_ControlledPurchasingDetails"
    ],
    "SuggestedSearch": [
      "SuggestedSearchTerm",
      "SuggestedProduct"
    ],
    "AddToBasketResponseTags": [
      "AddToBasketEnsighten",
      "AddToBasketGoogleAnalytics",
      "AddToBasketYandex",
      "AddToBasketTealium",
      "GA4Event_AddToCart"
    ]
  }
};
      export default result;
    