import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useLifecyclePageQuery } from '../../generated/graphql';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';

export const LifecycleEmailPageWrapper: FunctionComponent = () => {
  let location = useLocation();
  let token = new URLSearchParams(location.search).get('token');

  const { loading, error, data } = useLifecyclePageQuery({
    variables: { token: token ?? '' },
    ssr: false,
    skip: !token?.length,
  });

  if (!token?.length) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={404} />;
  }

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (!data.lifecyclePage.isSuccess || !data.lifecyclePage.html)
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={404} />;

  return <div dangerouslySetInnerHTML={{ __html: data.lifecyclePage.html }}></div>;
};

export default LifecycleEmailPageWrapper;
