import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useCategoryPageQuery } from '../../generated/graphql';
import { CategoryListPage } from '../../rs-emd-ui-modules/src/components/category-list-page';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import { predictiveSearchService } from '../../services/predictive-search-service';
import SRPWrapper from '../srp/srp-wrapper-component';

type CLPWrapperProps = {
  contextInfo: IContextInfo;
};

export const CLPWrapper: FunctionComponent<CLPWrapperProps> = (props: CLPWrapperProps) => {
  let location = useLocation();
  let slugConstant = '/category/';

  // if query/tag redirect to SRP (b2tdvu)
  const searchQuery = new URLSearchParams(location.search).get('query');
  const tagName = new URLSearchParams(location.search).get('tag');
  const showSRP: boolean = (searchQuery !== null && searchQuery.length > 0) || (tagName !== null && tagName.length > 0);

  const { loading, error, data } = useCategoryPageQuery({
    variables: { slug: location.pathname.substring(location.pathname.lastIndexOf(slugConstant) + slugConstant.length) },
    ssr: true,
    skip: showSRP,
  });

  if (showSRP) return <SRPWrapper contextInfo={props.contextInfo} />;
  else {
    if (loading) {
      return <Loader />;
    } else if (error || !data) {
      return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
    } else if (!data.categoryListPage.isSuccess) {
      if (data.categoryListPage.redirectUrl) {
        return (
          <RedirectWithStatus to={{ pathname: data.categoryListPage.redirectUrl, search: location.search } as Location} statusCode={301} />
        );
      } else return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={404} />;
    }

    return <CategoryListPage data={data.categoryListPage} predictiveSearchService={predictiveSearchService}></CategoryListPage>;
  }
};

export default CLPWrapper;
