import {
  ICheckPCBAvailabilityRequest,
  ICheckPCBAvailabilityResult,
  IGetDataSheetsRequest,
  IGetDataSheetsResult,
  IGetStockDataRequest,
  IGetStockDataResult,
} from '../rs-emd-ui-modules/src/services/product/product.dto';
import {
  CheckPcbAvailabilityDocument,
  CheckPcbAvailabilityQuery,
  CheckPcbAvailabilityQueryVariables,
  DataSheetsDocument,
  DataSheetsQuery,
  DataSheetsQueryVariables,
  ProductDetailPageStockDocument,
  ProductDetailPageStockQuery,
  ProductDetailPageStockQueryVariables,
} from '../generated/graphql';
import { IProductService } from '../rs-emd-ui-modules/src/services/product/product-service';
import { graphQLService } from './graphql-service';
import { clickEvent, pushEvent } from '../tagging/ensighten';

export class ProductService implements IProductService {
  brandTrackerClickEvent(info: string, brand: string): void {
    const eventData = {
      __Type: 'buttonTracker',
      __Info: info,
      __BRAND: brand,
    };

    return clickEvent(eventData);
  }

  calibrationTrackerClickEvent(info: string, stockCode: string): void {
    const eventData = {
      __Type: 'buttonTracker',
      __Info: info,
      __Result: stockCode,
    };

    return clickEvent(eventData);
  }

  clickEvent(eventDataAttributes: any): void {
    return clickEvent(eventDataAttributes);
  }

  pushEvent(eventKey: string, eventData: any): void {
    return pushEvent(eventKey, eventData);
  }

  async getStockData(variables: IGetStockDataRequest): Promise<IGetStockDataResult> {
    const result = await graphQLService.apolloClient.query<ProductDetailPageStockQuery, ProductDetailPageStockQueryVariables>({
      query: ProductDetailPageStockDocument,
      variables: { stockCode: variables.stockCode },
      fetchPolicy: 'no-cache',
    });

    let res: IGetStockDataResult = {};
    if (result && result.data) {
      res.stockArea = result.data.productDetailPage.stockArea;
    }
    return res;
  }

  async getDataSheets(variables: IGetDataSheetsRequest): Promise<IGetDataSheetsResult> {
    const result = await graphQLService.apolloClient.query<DataSheetsQuery, DataSheetsQueryVariables>({
      query: DataSheetsDocument,
      variables: { stockCode: variables.stockCode, documentsType: variables.documentsType },
    });

    let res: IGetDataSheetsResult = {};
    if (result && result.data) {
      res.dataSheets = result.data.dataSheets;
    }
    return res;
  }

  async checkPCBAvailability(variables: ICheckPCBAvailabilityRequest): Promise<ICheckPCBAvailabilityResult> {
    const result = await graphQLService.apolloClient.query<CheckPcbAvailabilityQuery, CheckPcbAvailabilityQueryVariables>({
      query: CheckPcbAvailabilityDocument,
      variables: { manufacturer: variables.manufacturer, partNumber: variables.partNumber },
    });

    let res: ICheckPCBAvailabilityResult = {};
    if (result && result.data) {
      res.data = result.data.checkPCBAvailability;
    }
    return res;
  }
}

export const productService = new ProductService();
