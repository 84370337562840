import React, { FunctionComponent } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useTechnicalErrorPageQuery } from '../../generated/graphql';
import { TechnicalErrorPage } from '../../rs-emd-ui-modules/src/components/error-pages';

export const TechnicalErrorWrapper: FunctionComponent = () => {
  let location = useLocation();
  let sessionId = new URLSearchParams(location.search).get('sessionId');

  const { loading, error, data } = useTechnicalErrorPageQuery({
    ssr: true,
  });

  if (loading) {
    return <Loader />;
  } else {
    if (!sessionId) {
      return <Redirect to={`${process.env.REACT_APP_ERROR_ENDPOINT}?sessionId=${data?.technicalErrorPage.sessionId}`} />;
    } else {
      return <TechnicalErrorPage data={data?.technicalErrorPage}></TechnicalErrorPage>;
    }
  }
};

export default TechnicalErrorWrapper;
