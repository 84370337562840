import loadable from '@loadable/component';

// Parts List
const NewList = loadable(() => import(/* webpackChunkName: "parts-list" */ '../parts-list/new-list/new-list-component'), {
  resolveComponent: (components) => components.NewList,
});
const PartListProduct = loadable(
  () => import(/* webpackChunkName: "parts-list" */ '../parts-list/part-list-product/part-list-product-component'),
  {
    resolveComponent: (components) => components.PartListProduct,
  }
);
const PartList = loadable(() => import(/* webpackChunkName: "parts-list" */ '../parts-list/part-list/part-list-component'), {
  resolveComponent: (components) => components.PartList,
});
const PartLists = loadable(() => import(/* webpackChunkName: "parts-list" */ '../parts-list/part-lists/part-lists-component'), {
  resolveComponent: (components) => components.PartLists,
});

export { NewList, PartListProduct, PartList, PartLists };
