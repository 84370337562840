import React, { FunctionComponent, MouseEvent, ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Icon, Responsive } from 'rs-emd-ui-atoms';
import { BehaviorSubject } from 'rxjs';
import { ILinkNode, IMenuSection } from '../product-menu.vm';
import styles from './menu-level-component.module.scss';
import { ILabel } from '../../../../models/common/label.model';
import { getLabel } from '../../../../helpers/html.utils';

type MenuLevelProps = {
  labels?: ILabel[];
  level: number;
  sections: IMenuSection[];
  cardClickCallback: (
    e: MouseEvent,
    level: number,
    open?: boolean,
    categoryLinkEnabled?: boolean,
    categoryUrl?: string,
    linkNode?: ILinkNode
  ) => void;
  hide?: boolean;
  activeCategory?: number | string;
  isMobile?: boolean;
  categoryLinkEnabled?: boolean;
  shopAllLabel: string;
  menuSubject?: BehaviorSubject<boolean>;
};

export const MenuLevel: FunctionComponent<MenuLevelProps> = (props: MenuLevelProps) => {
  const [isReadMoreClicked, setIsReadMoreClicked] = useState(false);

  return (
    <div className={`${styles['inner-content']} ${styles[`menu-level-${props.level}`]} ${props.hide ? 'hide' : ''}`}>
      {props.sections.filter((s) => s.title !== undefined).map((section) => getCardSection(props.level, section))}
    </div>
  );

  function getCardSection(level: number, section: IMenuSection) {
    if (props.isMobile && !section.containsVATToggleonly && (section.links === undefined || Object.keys(section.links).length === 0)) {
      getPlaceholderData(section);
    }

    return (
      <div className={`${styles.section} ${styles[section.className]}`} key={`${props.level} ${section.title}`}>
        <Responsive breakpoints={['mobile', 'tablet']}>
          <div
            className={styles['section-title']}
            onClick={(e) => {
              level !== 1 && props.cardClickCallback(e, level - 1, false);
              setIsReadMoreClicked(false);
            }}>
            {level !== 1 && <Icon name='chev-left' width={24} height={24} className={styles.chev}></Icon>}
            <div className={styles['parent-category']}>
              <span className={props.level === 1 ? styles['level-1-title'] : ''}>{section.title}</span>
              {section.shopAll && (
                <Link
                  to={section.shopAll.url}
                  onClick={(e) => {
                    e.stopPropagation();
                    onLinkClick();
                  }}
                  className={`link ${styles['shop-all']}`}>
                  {props.shopAllLabel}
                </Link>
              )}
            </div>
          </div>
        </Responsive>
        <Responsive breakpoints={['mobile']}>
          {level !== 1 && section.description && section.description.length > 0 && section.description !== 'SEO TEXT' && (
            <div className={styles['section-description-container']}>
              <p
                className={`${styles['section-description']} ${isReadMoreClicked ? styles['expand-description'] : ''}`}
                dangerouslySetInnerHTML={{
                  __html: isReadMoreClicked
                    ? section.description
                    : section.description.startsWith('<p>')
                    ? //consider only the first <p> tag to avoid having any following text split in half vertically (EMD-1193)
                      section.description.split('</p>')[0].concat('</p>')
                    : section.description,
                }}
              />
              <span className={`link ${styles['read-more']}`} onClick={() => setIsReadMoreClicked(!isReadMoreClicked)}>
                {getLabel(props.labels, isReadMoreClicked ? 'read_less' : 'read_more')}
              </span>
            </div>
          )}
        </Responsive>
        <div className={styles['cards']}>
          {section.links &&
            Object.keys(section.links).map((key) => {
              const id = level === 3 ? 'cat-' + key : key.replace('cat-', '');

              let sectionNode = (
                <>
                  {section.links[key].node}
                  {section.links[key].node && (!section.links[key].isLink || level === 3) && (
                    <Icon className={styles['chev-right']} name='chev-right' width={24} height={24}></Icon>
                  )}
                </>
              );

              return (
                <>
                  <Responsive breakpoints={['mobile', 'desktop', 'lg-desktop', 'xl-desktop']}>
                    <div
                      id={id}
                      className={`${styles['section-nav']} ${
                        props.activeCategory?.toString() === id ? styles['selected-section-nav'] : ''
                      } ${section.links[key].isLink ? styles['section-nav-link'] : ''} ${level === 3 ? styles['level-3'] : ''}`}
                      onClick={(e: MouseEvent) => {
                        if (!section.links[key].isLink || level === 3) {
                          props.cardClickCallback(
                            e,
                            level + 1,
                            true,
                            section.links[key].isLink ? true : undefined,
                            level === 3 ? (section.links[key].node as ReactElement).props.href : undefined,
                            section.links[key]
                          );
                        } else if (section.links[key].isLink) {
                          props.cardClickCallback(e, level + 1, true, true, undefined, section.links[key]);
                        }

                        setIsReadMoreClicked(false);
                      }}>
                      {sectionNode}
                    </div>
                  </Responsive>
                  <Responsive breakpoints={['tablet']}>
                    <Card
                      id={id}
                      key={key}
                      className={`${styles.card} ${styles[`${key}`]}
                   ${props.activeCategory?.toString() === id ? styles.active : ''}
                   ${section.links[key].isLink ? styles.link : ''}`}
                      squareCorners={true}
                      onClick={(e: MouseEvent) =>
                        !section.links[key].isLink &&
                        props.cardClickCallback(e, level + 1, true, props.categoryLinkEnabled, undefined, section.links[key])
                      }
                      onMouseEnter={(e: MouseEvent) => !props.isMobile && props.cardClickCallback(e, level + 1, true)}
                      onMouseLeave={(e: MouseEvent) => !props.isMobile && props.cardClickCallback(e, level, true)}>
                      {sectionNode}
                    </Card>
                  </Responsive>
                </>
              );
            })}
        </div>
      </div>
    );
  }

  function getPlaceholderData(section: IMenuSection) {
    section.links['t1'] = { node: '', isLink: true };
    section.links['t2'] = { node: '', isLink: true };
    section.links['t3'] = { node: '', isLink: true };
    section.links['t4'] = { node: '', isLink: true };
  }

  function onLinkClick() {
    props.menuSubject?.next(false);
  }
};
