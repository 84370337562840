import React, { FunctionComponent } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useLoginPageQuery } from '../../generated/graphql';
import { sanitizeRelativeUrl } from '../../rs-emd-ui-modules/src';
import { LoginPage } from '../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { loginService } from '../../services/login-service';

type LoginWrapperProps = {};

export const LoginWrapper: FunctionComponent<LoginWrapperProps> = (props: LoginWrapperProps) => {
  let location = useLocation();
  const isRedirectFromForgotPassword = Boolean(JSON.parse(new URLSearchParams(location.search).get('forgotPassword') ?? '0'));
  const isRedirectFromResetPassword = Boolean(JSON.parse(new URLSearchParams(location.search).get('resetPassword') ?? '0'));
  const isRedirectFromSuccessfulRegister = Boolean(JSON.parse(new URLSearchParams(location.search).get('successRegister') ?? '0'));
  const isRedirectFromLogout = Boolean(JSON.parse(new URLSearchParams(location.search).get('logout') ?? '0'));

  const redirectionURL = new URLSearchParams(location.search).get('redirectTo');

  let history = useHistory();
  let identifier = (history.location.state as any)?.identifier;

  const { loading, error, data } = useLoginPageQuery({
    ssr: true,
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }
  //if user is already logged in
  else if (!data.loginPage.isSuccess) {
    if (isRedirectFromForgotPassword) return <Redirect to={`/user/profile?forgotPassword=1`} />;
    else if (redirectionURL) return <Redirect to={sanitizeRelativeUrl(redirectionURL)} />;
    return <Redirect to={'/user/myaccount'} />;
  }

  return (
    <LoginPage
      data={data.loginPage}
      identifier={identifier ?? undefined}
      isProfileEnabled={data.loginPage.isProfileEnabled}
      redirectFromForgotPassword={isRedirectFromForgotPassword}
      redirectFromResetPassword={isRedirectFromResetPassword}
      redirectFromSuccessfulRegister={isRedirectFromSuccessfulRegister}
      redirectFromLogout={isRedirectFromLogout}
      redirectionURL={redirectionURL ? sanitizeRelativeUrl(redirectionURL) : undefined}
      loginService={loginService}
    />
  );
};

export default LoginWrapper;
