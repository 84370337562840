import React, { FunctionComponent, useCallback, useEffect, useLayoutEffect, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Icon, isMobileDevice, Modal, ModalRef, TextRef } from 'rs-emd-ui-atoms';
import { getLabel } from '../../../helpers/html.utils';
import { includeVat } from '../../../helpers/user-settings.utils';
import { ISuggestedProduct, ISuggestedSearchTerm } from '../../../models/master/header/predictive-search.model';
import { isSuggestedProduct, isSuggestedSearchTerm } from '../../../models/master/header/predictive-search.utils';
import { SearchField } from '../../shared';
import { ProductSearch } from './product-search/product-search-component';
import { Action, SearchActionType, SearchProps, State } from './search-component.model';
import styles from './search-component.module.scss';

export const Search: FunctionComponent<SearchProps> = (props: SearchProps) => {
  const [state, dispatch] = useReducer(reducer, {
    isClearVisible: false,
    labels: props.labels,
    suggestedSearchTerms: [],
    suggestedSearchProducts: [],
  });

  const textInput = useRef<TextRef>(null);
  const modalRef = useRef<ModalRef>(null);
  const mobileModalHeaderBtnsDivRef = useRef<HTMLDivElement>(null);
  const focusGuardRef = useRef<HTMLDivElement>(null);

  const onSearchChange = useCallback(
    (searchQuery: string) => {
      if (process.env.REACT_APP_PREDICTIVE_SEARCH_ENABLED === '1') {
        if (searchQuery.length > 2) {
          //update suggested searches
          props.predictiveSearchService.getSuggestedSearches({ variables: { searchQuery: searchQuery } }).then((result) => {
            dispatch({ type: SearchActionType.SetSuggestedSearches, data: result });

            //get stock codes from ISuggestedProducts
            let stockCodes = result.getSuggestedSearches?.searchTerms
              .filter((searchTerm) => isSuggestedProduct(searchTerm))
              .map((searchTerm) => {
                return (searchTerm as ISuggestedProduct).stockCode;
              });

            //get product details if there are product stock codes
            if (stockCodes && stockCodes?.length > 0) {
              props.predictiveSearchService.productDetailPages({ variables: { stockCodes: stockCodes } }).then((result) => {
                dispatch({ type: SearchActionType.SetProductDetailPages, data: result });
              });
            }
          });
        } else {
          // to hide suggested products
          dispatch({ type: SearchActionType.ResetSuggestions });
        }
      }
    },
    [props.predictiveSearchService]
  );

  // on mobile, focus search field
  useEffect(() => {
    textInput.current?.focus();
  }, []);

  // trigger search
  useEffect(() => {
    props.searchString && onSearchChange(props.searchString);
    if (props.searchString === '') dispatch({ type: SearchActionType.ResetSuggestions });
  }, [props.searchString, onSearchChange]);

  useEffect(() => {
    if (props.closeSearchModal) {
      const e: any = {};
      modalRef.current?.closeModal(e);
    }
  }, [props.closeSearchModal]);

  // on load, get recent searches
  useLayoutEffect(() => {
    // set a clean up flag
    let isSubscribed = true;

    props.predictiveSearchService.getRecentSearches().then((result) => {
      if (isSubscribed) dispatch({ type: SearchActionType.SetRecentSearches, data: result });
    });

    // cancel subscription to effect
    // since users can click in and out of the search before async call returns,
    // need to cancel async task to avoid memory leaks
    return () => {
      isSubscribed = false;
    };
  }, [props.predictiveSearchService]);

  return (
    <Modal
      ref={modalRef}
      hideCloseIcon={true}
      hideOverlay={true}
      isMobileFullScreen={true}
      modalContainerClassName={styles['search-container']}
      className={styles['modal']}
      closeCallback={() => {
        props.toggleSearch();
      }}
      headerElt={
        isMobileDevice() ? (
          <div className={styles.header}>
            <div className={styles['search-field']}>
              <SearchField
                predictiveSearchService={props.predictiveSearchService}
                isPredictiveSearchEnabled={false}
                labels={props.labels}
                placeholder={getLabel(props.labels, 'find_what_you_need')}
                onSearchChange={onSearchChange}></SearchField>
            </div>
            <Icon name='close' width={24} height={24} className={styles.close} onClick={onCloseMenuClick}></Icon>
          </div>
        ) : null
      }>
      <div tabIndex={0}>
        {/* Search Criteria */}
        {(!state.recentSearches || state.recentSearches.length === 0) &&
          state.suggestedSearchTerms?.length === 0 &&
          state.suggestedSearchProducts?.length === 0 && (
            <div className={styles.info}>
              <p className={styles.label}>{getLabel(props.labels, 'search_using')}</p>
              <ul>
                {getLabel(props.labels, 'search_criterias')
                  .split('|')
                  .map((criteria) => {
                    return <li key={criteria}>{criteria}</li>;
                  })}
              </ul>
            </div>
          )}
        {/*  Recent Searches */}
        {state.recentSearches &&
          state.recentSearches.length > 0 &&
          state.suggestedSearchTerms?.length === 0 &&
          state.suggestedSearchProducts?.length === 0 && (
            <div className={styles['recent-searches']}>
              <p className={styles.label}>{getLabel(state.labels, 'recent_searches')}</p>
              {state.recentSearches?.map((recentSearch) => {
                return (
                  <div key={recentSearch.searchQuery}>
                    <div onClick={onCloseMenuClick}>
                      <Link className={styles['recent-link']} to={recentSearch.url}>
                        {recentSearch.searchQuery}
                      </Link>
                    </div>
                    <div onClick={() => onRemoveRecentSearch(recentSearch.searchQuery)}>
                      <Icon name='close' className={styles['close-icon']} width={24} height={16} />
                    </div>
                  </div>
                );
              })}
              <p className={`link ${styles['clear-all-recent']}`} onClick={(e) => onRemoveAllRecentSearch(e)}>
                {getLabel(state.labels, 'clear_all_recent_searches')}
              </p>
            </div>
          )}

        {/**Suggested Searches */}
        {/** Only show suggested searches if the length of the search string is > 2 */}
        {((state.suggestedSearchTerms && state.suggestedSearchTerms.length !== 0) ||
          (state.suggestedSearchProducts && state.suggestedSearchProducts.length !== 0)) && (
          <div className={styles['suggested-searches']}>
            {/**Suggested Search Terms */}
            {state.suggestedSearchTerms && state.suggestedSearchTerms.length !== 0 && (
              <p className={styles.label}>{getLabel(state.labels, 'suggested_searches')}</p>
            )}
            {state.suggestedSearchTerms?.map((searchTerm, index) => {
              if (isSuggestedSearchTerm(searchTerm)) {
                return (
                  <div onClick={(e) => onCloseMenuClick(e)} className={styles['suggested-link']} key={searchTerm.url}>
                    {/* TODO: switch to Link after campaign pages are launched */}
                    <a
                      key={index}
                      href={searchTerm.url}
                      data-gtm={'suggested-search-term'}
                      onClick={(e) => onSuggestedSearchTermClick(e, searchTerm)}>
                      <div className={styles['suggested-search-info']}>
                        <p
                          className={styles['suggested-search']}
                          dangerouslySetInnerHTML={{
                            __html: searchTerm.searchQuery.replace(
                              searchTerm.highlightedFragment,
                              `<b>${searchTerm.highlightedFragment}</b>`
                            ),
                          }}
                        />
                        {searchTerm.noOfMatches && <p className={styles['extra-info']}>({searchTerm.noOfMatches})</p>}
                      </div>
                    </a>
                    {searchTerm.parentSuggest && (
                      <Link to={searchTerm.parentSuggest.url}>
                        <p className={styles.shop}>{searchTerm.parentSuggest.searchQuery}</p>
                      </Link>
                    )}
                  </div>
                );
              }
            })}

            {/**Suggested Products */}
            {state.suggestedSearchProducts && state.suggestedSearchProducts.length !== 0 && (
              <p className={`${styles.label} ${styles['suggested-products-text']}`}>{getLabel(state.labels, 'suggested_products')}</p>
            )}
            {state.suggestedSearchProducts?.map((searchTerm, index) => {
              if (isSuggestedProduct(searchTerm)) {
                //get details of current searchTerm
                let productDetailPage = state.productDetailPages?.filter(
                  (productPage) => productPage.titleArea?.stockCode === searchTerm.stockCode
                )[0];

                return (
                  <>
                    {searchTerm.isAlternative && <p className={styles.label}>{getLabel(state.labels, 'alternative_product')}</p>}

                    <ProductSearch
                      key={searchTerm.stockCode}
                      labels={state.labels}
                      product={searchTerm}
                      productDetailPage={productDetailPage}
                      isAlternative={searchTerm.isAlternative}
                      incVat={includeVat()}
                      onClick={(e) => {
                        onCloseMenuClick(e);

                        props.searchString &&
                          props.predictiveSearchService.addRecentSearch({ variables: { searchQuery: props.searchString } });
                      }}
                    />

                    {searchTerm.isAlternative && searchTerm.comparisonPageUrl && (
                      <Link
                        className={styles['suggested-products-comparison-link']}
                        to={searchTerm.comparisonPageUrl}
                        onClick={(e) => onCloseMenuClick(e)}>
                        {getLabel(state.labels, 'view_comparison')}
                      </Link>
                    )}
                  </>
                );
              }
            })}
          </div>
        )}
        <div
          ref={focusGuardRef}
          id='focusguard'
          onKeyDown={(e) => {
            if (isMobileDevice() && !e.shiftKey && e.key === 'Tab') {
              mobileModalHeaderBtnsDivRef.current?.focus();
            }
          }}
          tabIndex={0}
        />
      </div>
    </Modal>
  );

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case SearchActionType.SetRecentSearches:
        return {
          ...state,
          labels: [...(state.labels ?? []), ...(action.data.getRecentSearches?.labels ?? [])],
          recentSearches: action.data.getRecentSearches?.recentSearches,
        };
      case SearchActionType.RemoveRecentSearch:
        return {
          ...state,
          recentSearches: state.recentSearches
            ? state.recentSearches.filter((search) => search.searchQuery !== action.data.variables.searchQuery)
            : [],
        };
      case SearchActionType.SetSuggestedSearches:
        return {
          ...state,
          labels: [...(state.labels ?? []), ...(action.data.getSuggestedSearches?.labels ?? [])],
          suggestedSearchTerms: action.data.getSuggestedSearches?.searchTerms?.filter((search) => isSuggestedSearchTerm(search)),
          suggestedSearchProducts: action.data.getSuggestedSearches?.searchTerms?.filter((search) => isSuggestedProduct(search)),
        };
      case SearchActionType.SetProductDetailPages:
        return {
          ...state,
          productDetailPages: action.data.productDetailPages,
        };
      case SearchActionType.ResetSuggestions:
        return {
          ...state,
          suggestedSearchTerms: [],
          suggestedSearchProducts: [],
        };
    }
  }

  function onCloseMenuClick(e: any) {
    modalRef.current?.closeModal(e);
  }

  function onRemoveRecentSearch(searchQuery: string) {
    props.predictiveSearchService.removeRecentSearch({ variables: { searchQuery: searchQuery } });

    dispatch({ type: SearchActionType.RemoveRecentSearch, data: { variables: { searchQuery: searchQuery } } });
  }

  function onRemoveAllRecentSearch(e: any) {
    onCloseMenuClick(e);
    props.predictiveSearchService.removeAllRecentSearches();
  }

  function onSuggestedSearchTermClick(e: any, searchTerm: ISuggestedSearchTerm) {
    //to wait for addRecentSearch to complete before redirecting to different page
    e.preventDefault();

    props.predictiveSearchService.addRecentSearch({ variables: { searchQuery: searchTerm.searchQuery } }).then(() => {
      window.location.href = searchTerm.url;
    });
  }
};
