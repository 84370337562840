import { createContext } from 'react';
import { QuickOrderProps } from '../../rs-emd-ui-modules/src/components/basket-purchase/basket/quick-order/quick-order-component.model';

// Removes labels from props as they are passed from master
export type QuickOrderContextProps = { isVisible?: boolean } & Omit<QuickOrderProps, 'labels'>;

interface IQuickOrderContextValue {
  quickOrderProps?: QuickOrderContextProps;
  setQuickOrderProps: (quickOrderProps?: QuickOrderContextProps) => void;
  setQuickOrderVisible: (visible: boolean) => void;
}

export const QuickOrderContext = createContext<IQuickOrderContextValue>({ setQuickOrderProps: () => {}, setQuickOrderVisible: () => {} });
