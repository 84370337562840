import {
  AddressListV2Document,
  AddressListV2Query,
  AddressListV2QueryVariables,
  ConfirmBasketAsOrderDocument,
  ConfirmBasketAsOrderMutation,
  ConfirmBasketAsOrderMutationVariables,
  ConfirmBasketAsQuoteDocument,
  ConfirmBasketAsQuoteMutation,
  ConfirmBasketAsQuoteMutationVariables,
  ContactDetailsDocument,
  ContactDetailsQuery,
  ContactDetailsQueryVariables,
  CountryAddressConfigurationDocument,
  CountryAddressConfigurationQuery,
  CountryAddressConfigurationQueryVariables,
  DeleteAddressDocument,
  DeleteAddressMutation,
  DeleteAddressMutationVariables,
  GetPaymentOptionsDocument,
  GetPaymentOptionsQuery,
  GetPaymentOptionsQueryVariables,
  PurchasePageSummaryDocument,
  PurchasePageSummaryQuery,
  PurchasePageSummaryQueryVariables,
  SaveBillingAddressDocument,
  SaveBillingAddressMutation,
  SaveBillingAddressMutationVariables,
  SaveContactDetailsDocument,
  SaveContactDetailsMutation,
  SaveContactDetailsMutationVariables,
  SaveDeliveryAddressDocument,
  SaveDeliveryAddressMutation,
  SaveDeliveryAddressMutationVariables,
  SavePersonalOrderReferenceDocument,
  SavePersonalOrderReferenceMutation,
  SavePersonalOrderReferenceMutationVariables,
  SearchAddressDocument,
  SearchAddressQuery,
  SearchAddressQueryVariables,
  SetBasketBillingAddressDocument,
  SetBasketBillingAddressMutation,
  SetBasketBillingAddressMutationVariables,
  SetBasketDeliveryAddressDocument,
  SetBasketDeliveryAddressMutation,
  SetBasketDeliveryAddressMutationVariables,
  SetBasketPaymentOptionDocument,
  SetBasketPaymentOptionMutation,
  SetBasketPaymentOptionMutationVariables,
  ValidateVatDetailsDocument,
  ValidateVatDetailsQuery,
  ValidateVatDetailsQueryVariables,
} from '../generated/graphql';
import { IPurchaseService } from '../rs-emd-ui-modules/src/services/purchase/purchase-service';
import {
  IAddressListRequest,
  IAddressListResult,
  IConfirmOrderRequest,
  IConfirmOrderResult,
  IConfirmQuoteRequest,
  IConfirmQuoteResult,
  ICountryAddressConfigurationRequest,
  ICountryAddressConfigurationResult,
  IDeleteAddressRequest,
  IDeleteAddressResult,
  IGetPaymentOptionsResult,
  IPurchasePageSummaryResponse,
  IPurchase_ContactDetailsResult,
  IPurchase_SaveContactDetailsRequest,
  IPurchase_SaveContactDetailsResult,
  ISaveAddressRequest,
  ISaveAddressResult,
  ISavePersonalOrderReferenceRequest,
  ISavePersonalOrderReferenceResult,
  ISearchAddressRequest,
  ISearchAddressResponse,
  ISetBasketAddressRequest,
  ISetBasketAddressResult,
  ISetBasketPaymentOptionRequest,
  ISetBasketPaymentOptionResult,
  IValidateVATDetailsRequest,
  IValidateVATDetailsResult,
} from '../rs-emd-ui-modules/src/services/purchase/purchase.dto';
import { pushGA4Event } from '../tagging/ga4';
import { pushEvent } from './../tagging/ensighten';
import { graphQLService } from './graphql-service';

export class PurchaseService implements IPurchaseService {
  pushEvent(eventData: any): void {
    return pushEvent('checkout.paymentDetails.Update', eventData);
  }

  async saveContactDetails(req: IPurchase_SaveContactDetailsRequest): Promise<IPurchase_SaveContactDetailsResult> {
    const result = await graphQLService.apolloClient.mutate<SaveContactDetailsMutation, SaveContactDetailsMutationVariables>({
      mutation: SaveContactDetailsDocument,
      variables: { data: req.data },
    });

    let res: IPurchase_SaveContactDetailsResult = {};

    if (result.data) {
      res.data = result.data.saveContactDetails;

      if (result.data.saveContactDetails.isSuccess) {
        const tag = {
          event: 'checkout_user_details',
          ecommerce: {
            email: req.data.email,
          },
        };

        pushGA4Event(tag);
      }
    }

    return res;
  }

  async contactDetails(): Promise<IPurchase_ContactDetailsResult> {
    const result = await graphQLService.apolloClient.query<ContactDetailsQuery, ContactDetailsQueryVariables>({
      query: ContactDetailsDocument,
      fetchPolicy: 'no-cache',
    });

    let res: IPurchase_ContactDetailsResult = {};

    if (result.data) {
      res.data = result.data.purchasePage.contactDetails;
    }

    return res;
  }

  async countryAddressConfiguration(req: ICountryAddressConfigurationRequest): Promise<ICountryAddressConfigurationResult> {
    const result = await graphQLService.apolloClient.query<CountryAddressConfigurationQuery, CountryAddressConfigurationQueryVariables>({
      query: CountryAddressConfigurationDocument,
      variables: { countryCode: req.countryCode },
    });

    let res: ICountryAddressConfigurationResult = {};

    if (result.data) {
      res.data = result.data.countryAddressConfiguration;
    }

    return res;
  }

  async addressListV2(req: IAddressListRequest): Promise<IAddressListResult> {
    const result = await graphQLService.apolloClient.query<AddressListV2Query, AddressListV2QueryVariables>({
      query: AddressListV2Document,
      variables: req,
      fetchPolicy: 'no-cache',
    });

    let res: IAddressListResult = {};

    if (result.data) {
      res.data = result.data.addressListV2;
    }

    return res;
  }

  async setBasketDeliveryAddress(req: ISetBasketAddressRequest): Promise<ISetBasketAddressResult> {
    const result = await graphQLService.apolloClient.mutate<SetBasketDeliveryAddressMutation, SetBasketDeliveryAddressMutationVariables>({
      mutation: SetBasketDeliveryAddressDocument,
      variables: req,
    });

    let res: ISetBasketAddressResult = {};

    if (result.data) {
      res.data = result.data.setBasketDeliveryAddress;
    }

    return res;
  }

  async setBasketBillingAddress(req: ISetBasketAddressRequest): Promise<ISetBasketAddressResult> {
    const result = await graphQLService.apolloClient.mutate<SetBasketBillingAddressMutation, SetBasketBillingAddressMutationVariables>({
      mutation: SetBasketBillingAddressDocument,
      variables: req,
      fetchPolicy: 'no-cache',
    });

    let res: ISetBasketAddressResult = {};

    if (result.data) {
      res.data = result.data.setBasketBillingAddress;
    }

    return res;
  }

  async saveDeliveryAddress(req: ISaveAddressRequest): Promise<ISaveAddressResult> {
    //Issue cause by __typename returned by the GET query (purchasePage query) which is not the one expected by the mutation
    //https://github.com/apollographql/apollo-client/issues/1913#issuecomment-393721604
    const omitTypename = (key: string, value: string) => (key === '__typename' ? undefined : value);
    const newReq = JSON.parse(JSON.stringify(req), omitTypename);

    const result = await graphQLService.apolloClient.mutate<SaveDeliveryAddressMutation, SaveDeliveryAddressMutationVariables>({
      mutation: SaveDeliveryAddressDocument,
      variables: newReq,
    });

    let res: ISaveAddressResult = {};

    if (result.data) {
      res.data = result.data.saveDeliveryAddress;
    }

    return res;
  }

  async saveBillingAddress(req: ISaveAddressRequest): Promise<ISaveAddressResult> {
    //Issue cause by __typename returned by the GET query (purchasePage query) which is not the one expected by the mutation
    //https://github.com/apollographql/apollo-client/issues/1913#issuecomment-393721604
    const omitTypename = (key: string, value: string) => (key === '__typename' ? undefined : value);
    const newReq = JSON.parse(JSON.stringify(req), omitTypename);

    const result = await graphQLService.apolloClient.mutate<SaveBillingAddressMutation, SaveBillingAddressMutationVariables>({
      mutation: SaveBillingAddressDocument,
      variables: newReq,
    });

    let res: ISaveAddressResult = {};

    if (result.data) {
      res.data = result.data.saveBillingAddress;
    }

    return res;
  }

  async setBasketPaymentOption(req: ISetBasketPaymentOptionRequest): Promise<ISetBasketPaymentOptionResult> {
    const result = await graphQLService.apolloClient.mutate<SetBasketPaymentOptionMutation, SetBasketPaymentOptionMutationVariables>({
      mutation: SetBasketPaymentOptionDocument,
      variables: req.data,
    });

    let res: ISetBasketPaymentOptionResult = {};

    if (result.data) {
      res.data = result.data.setBasketPaymentOption;
    }

    return res;
  }

  async confirmBasketAsOrder(req: IConfirmOrderRequest): Promise<IConfirmOrderResult> {
    const result = await graphQLService.apolloClient.mutate<ConfirmBasketAsOrderMutation, ConfirmBasketAsOrderMutationVariables>({
      mutation: ConfirmBasketAsOrderDocument,
      variables: req,
    });

    let res: IConfirmOrderResult = {};

    if (result.data) {
      res.data = result.data.confirmBasketAsOrder;
    }

    return res;
  }

  async confirmBasketAsQuote(req: IConfirmQuoteRequest): Promise<IConfirmQuoteResult> {
    //Issue cause by __typename returned by the GET query (purchasePage query) which is not the one expected by the mutation
    //https://github.com/apollographql/apollo-client/issues/1913#issuecomment-393721604
    const omitTypename = (key: string, value: string) => (key === '__typename' ? undefined : value);
    const newReq = JSON.parse(JSON.stringify(req), omitTypename);

    const result = await graphQLService.apolloClient.mutate<ConfirmBasketAsQuoteMutation, ConfirmBasketAsQuoteMutationVariables>({
      mutation: ConfirmBasketAsQuoteDocument,
      variables: newReq,
    });

    let res: IConfirmQuoteResult = {};

    if (result.data) {
      res.data = result.data.confirmBasketAsQuote;
    }

    return res;
  }

  async savePersonalOrderReference(req: ISavePersonalOrderReferenceRequest): Promise<ISavePersonalOrderReferenceResult> {
    const result = await graphQLService.apolloClient.mutate<
      SavePersonalOrderReferenceMutation,
      SavePersonalOrderReferenceMutationVariables
    >({
      mutation: SavePersonalOrderReferenceDocument,
      variables: req,
    });

    let res: ISavePersonalOrderReferenceResult = {};

    if (result.data) {
      res.data = result.data.savePersonalOrderReference;
    }

    return res;
  }

  async deleteAddress(req: IDeleteAddressRequest): Promise<IDeleteAddressResult> {
    const result = await graphQLService.apolloClient.mutate<DeleteAddressMutation, DeleteAddressMutationVariables>({
      mutation: DeleteAddressDocument,
      variables: req,
    });

    let res: IDeleteAddressResult = {};

    if (result.data) {
      res.data = result.data.deleteAddress;
    }

    return res;
  }

  async getPaymentOptions(): Promise<IGetPaymentOptionsResult> {
    const result = await graphQLService.apolloClient.query<GetPaymentOptionsQuery, GetPaymentOptionsQueryVariables>({
      query: GetPaymentOptionsDocument,
      fetchPolicy: 'no-cache',
    });

    let res: IGetPaymentOptionsResult = {};

    if (result.data) {
      res.data = result.data.purchasePage.paymentOptions;
    }

    return res;
  }

  async validateVATDetails(variables: IValidateVATDetailsRequest): Promise<IValidateVATDetailsResult> {
    const result = await graphQLService.apolloClient.query<ValidateVatDetailsQuery, ValidateVatDetailsQueryVariables>({
      query: ValidateVatDetailsDocument,
      variables: variables,
      fetchPolicy: 'no-cache',
    });

    let res: IValidateVATDetailsResult = {};

    if (result.data) {
      res.data = result.data.validateVATDetails;
    }

    return res;
  }

  async searchAddress(variables: ISearchAddressRequest): Promise<ISearchAddressResponse> {
    const result = await graphQLService.apolloClient.query<SearchAddressQuery, SearchAddressQueryVariables>({
      query: SearchAddressDocument,
      variables: variables,
    });

    let res: ISearchAddressResponse = {};

    if (result.data) {
      res.data = result.data.searchAddress;
    }

    return res;
  }

  async purchasePageSummary(): Promise<IPurchasePageSummaryResponse> {
    const result = await graphQLService.apolloClient.query<PurchasePageSummaryQuery, PurchasePageSummaryQueryVariables>({
      query: PurchasePageSummaryDocument,
      fetchPolicy: 'no-cache',
    });

    let res: IPurchasePageSummaryResponse = {};

    if (result.data) {
      res.data = {
        summary: result.data.purchasePage.summary,
      };
    }

    return res;
  }
}

export const purchaseService = new PurchaseService();
