import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useNoResultsPageQuery } from '../../generated/graphql';
import { NoResults } from '../../rs-emd-ui-modules/src/components/product-list-page/no-results';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';

export const NoResultsWrapper: FunctionComponent = () => {
  let location = useLocation();

  const searchQuery = new URLSearchParams(location.search).get('query');
  const stockCode = new URLSearchParams(location.search).get('stockcode');

  const { loading, error, data } = useNoResultsPageQuery({
    variables: {
      searchQuery: searchQuery ?? stockCode ?? '',
      isProductNotFound: location.pathname.toLowerCase() === '/productpage/productnotfound',
    },
    ssr: true,
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }

  return <NoResults data={data.noResultsPage}></NoResults>;
};

export default NoResultsWrapper;
