import { IPDPCADData } from '../../models/pdp/cad-documents/pdp-cad-data.model';
import { IDocumentItem } from '../../models/common/document-item.model';
import { IPDPPricing } from '../../models/pdp/pdp-pricing.model';
import { IStock } from '../../models/common/stock.model';
import { IPDPTitle } from '../../models/pdp/pdp-title.model';

export enum PDPQueryType {
  UpdatePPKData = 'UpdatePPKData',
  UpdateCalibrationData = 'UpdateCalibrationData',
  GetDataSheets = 'GetDataSheets',
  GetCadDocuments = 'GetCadDocuments',
  GetTracePartsDocumentUrl = 'GetTracePartsDocumentUrl',
  GetStockData = 'GetStockData',
}

export type PDPRequest =
  | { type: PDPQueryType.UpdatePPKData; stockCode: string }
  | { type: PDPQueryType.UpdateCalibrationData; stockCode: string }
  | { type: PDPQueryType.GetDataSheets; stockCode: string; documentsType: string }
  | { type: PDPQueryType.GetCadDocuments; stockCode: string; tracePartsPartId?: string | null }
  | { type: PDPQueryType.GetTracePartsDocumentUrl; stockCode: string; documentId: number; tracePartsPartId?: string | null };

export type PDPResponse =
  | {
      type: PDPQueryType.UpdatePPKData;
      data: {
        titleArea?: IPDPTitle | null;
        priceArea?: IPDPPricing | null;
        stockArea?: IStock | null;
      };
    }
  | {
      type: PDPQueryType.UpdateCalibrationData;
      data: {
        titleArea?: IPDPTitle | null;
        priceArea?: IPDPPricing | null;
        stockArea?: IStock | null;
      };
    }
  | {
      type: PDPQueryType.GetDataSheets;
      data: {
        dataSheets: IDocumentItem[];
        documentsType: string;
      };
    }
  | {
      type: PDPQueryType.GetCadDocuments;
      data: {
        cadDocuments: IPDPCADData;
      };
    }
  | {
      type: PDPQueryType.GetTracePartsDocumentUrl;
      data: {
        tracePartsDocumentUrl: string;
      };
    };
