import React, { FunctionComponent } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { usePurchasePageQuery } from '../../generated/graphql';
import { Purchase } from '../../rs-emd-ui-modules/src/components/basket-purchase/purchase';
import { RequestQuote } from '../../rs-emd-ui-modules/src/components/basket-purchase/request-quote';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import { purchaseService } from '../../services/purchase-service';

type PurchasePageWrapperProps = {
  contextInfo: IContextInfo;
};

export const PurchaseWrapper: FunctionComponent<PurchasePageWrapperProps> = (props: PurchasePageWrapperProps) => {
  let location = useLocation();

  const isGuestOrFirstTimeUser = Boolean(JSON.parse(new URLSearchParams(location.search).get('guest') ?? '1'));
  const isPaymentRejected = String(new URLSearchParams(location.search).get('status') ?? '') === 'rejected';

  const { loading, error, data } = usePurchasePageQuery({
    variables: {},
    ssr: true,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (!data.purchasePage.isSuccess) {
    return <Redirect to='/cart' />;
  } else if (!data.purchasePage.labels || data.purchasePage.labels.length === 0) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={404} />;
  } else if (data.purchasePage.isQuote) {
    return (
      <RequestQuote
        data={data.purchasePage}
        isGuestOrFirstTimeUser={isGuestOrFirstTimeUser}
        locale={props.contextInfo.locale}
        purchaseService={purchaseService}
      />
    );
  }

  return (
    <Purchase
      data={data.purchasePage}
      isGuest={isGuestOrFirstTimeUser}
      isFirstTimeUser={isGuestOrFirstTimeUser}
      locale={props.contextInfo.locale}
      isPaymentRejected={isPaymentRejected}
      purchaseService={purchaseService}
    />
  );
};

export default PurchaseWrapper;
