import loadable from '@loadable/component';

const RequestQuote = loadable(() => import(/* webpackChunkName: "request-quote" */ './request-quote/request-quote-component'), {
  resolveComponent: (components) => components.RequestQuote,
});

const RequestQuoteHeader = loadable(
  () => import(/* webpackChunkName: "request-quote" */ './request-quote-header/request-quote-header-component'),
  {
    resolveComponent: (components) => components.RequestQuoteHeader,
  }
);

const RequestQuoteSummary = loadable(
  () => import(/* webpackChunkName: "request-quote" */ './request-quote-summary/request-quote-summary-component'),
  {
    resolveComponent: (components) => components.RequestQuoteSummary,
  }
);

export { RequestQuote, RequestQuoteHeader, RequestQuoteSummary };
