import loadable from '@loadable/component';

const MyAccountOrders = loadable(() => import(/* webpackChunkName: "my-account-orders" */ './my-account-orders-component'), {
  resolveComponent: (components) => components.MyAccountOrders,
});

const OrderProductList = loadable(
  () => import(/* webpackChunkName: "my-account-orders" */ './order-product-list/order-product-list-component'),
  {
    resolveComponent: (components) => components.OrderProductList,
  }
);

const OrderList = loadable(() => import(/* webpackChunkName: "my-account-orders" */ './order-list/order-list-component'), {
  resolveComponent: (components) => components.OrderList,
});

const OrderListRow = loadable(
  () => import(/* webpackChunkName: "my-account-orders" */ './order-list/order-list-row/order-list-row-component'),
  {
    resolveComponent: (components) => components.OrderListRow,
  }
);

const OrderListHeader = loadable(
  () => import(/* webpackChunkName: "my-account-orders" */ './order-list/order-list-header/order-list-header-component'),
  {
    resolveComponent: (components) => components.OrderListHeader,
  }
);

export { MyAccountOrders, OrderProductList, OrderList, OrderListRow, OrderListHeader };
