import loadable from '@loadable/component';

const CategoryListPage = loadable(() => import(/* webpackChunkName: "clp" */ './category-list-page-component'), {
  resolveComponent: (components) => components.CategoryListPage,
});

const LevelChildren = loadable(() => import(/* webpackChunkName: "clp" */ './level-children/level-children-component'), {
  resolveComponent: (components) => components.LevelChildren,
});

export { CategoryListPage, LevelChildren };
