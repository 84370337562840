import { IPartsListPage } from '../../models/parts-list-page/parts-list-page.model';

export enum PartsListQueryType {
  GetPartsList = 'GetPartsList',
  GetPartsListProducts = 'GetPartsListProducts',
}

export type PartsListRequest =
  | { type: PartsListQueryType.GetPartsList; contextStockCode?: string }
  | { type: PartsListQueryType.GetPartsListProducts; partsListId: number };

export type PartsListResponse = {
  type: PartsListQueryType;
  data: IPartsListPage;
};
