import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import EmailCampaignPageWrapper from '../../campaigns/email-campaign/email-campaign-wrapper-component';
import DocumentPageWrapper from '../../document/document-page-wrapper-component';
import { StyledRouter } from './styled-router-component';
import LifecycleEmailPageWrapper from '../../lifecycle-email/lifecycle-email-wrapper-component';

export const BaseRouter: FunctionComponent = () => {
  return (
    <Switch>
      {/* START: Routes without any styling */}
      <Route path='(.*)/content/user/viewinbrowser'>
        <EmailCampaignPageWrapper />
      </Route>
      <Route path='(.*)/content/user/lifecycleviewinbrowser'>
        <LifecycleEmailPageWrapper />
      </Route>
      <Route path='(.*)/content/document/:type/:stockCode'>
        <DocumentPageWrapper />
      </Route>

      {/* END: Routes without any styling */}

      <Route path='/'>
        <StyledRouter />
      </Route>
    </Switch>
  );
};
