import React, { FunctionComponent, MouseEvent } from 'react';
import { Button, CustomScrollbar, Icon, Responsive } from 'rs-emd-ui-atoms';
import { getAttributes } from '../../../../helpers/html.utils';
import { IFeaturedBrands } from '../../../../models/menu/brands/featured-brands.model';
import styles from './featured-brands-component.module.scss';

type BrandsProps = {
  featuredBrands?: IFeaturedBrands;
  cardClickCallback: (e: MouseEvent, level: number, open?: boolean, categoryLinkEnabled?: boolean) => void;
  hide?: boolean;
  isMobile?: boolean;
  parentLabel: string;
  featuredBrandsLabel: string;
};

export const FeaturedBrands: FunctionComponent<BrandsProps> = (props: BrandsProps) => {
  return getBrandSection();

  function getBrandSection() {
    let brandSection = (
      <div className={styles['featured-brands']}>
        <div className={styles['section-title']} onClick={(e) => props.cardClickCallback(e, 1, false)}>
          <Icon name='chev-left' width={24} height={24} className={styles.chev}></Icon>
          <div className={styles['parent-category']}>{props.parentLabel}</div>
        </div>
        <div className={styles.brands}>
          <p className={styles.heading}>{props.featuredBrandsLabel}</p>
          <div className={styles.content}>
            <div className={styles.thumbnails}>
              {props.featuredBrands?.brands &&
                props.featuredBrands.brands.map((brand) => {
                  return (
                    <a href={brand.link.url} {...getAttributes(brand.link.attributes)} key={brand.name} className={styles['image-link']}>
                      <div className={styles['img-container']}>
                        <img src={brand.imageUrl} alt={brand.name} />
                      </div>
                    </a>
                  );
                })}
            </div>
            {props.featuredBrands?.brands && (
              <div className={styles['view-all']}>
                <Button
                  buttonType='primary'
                  text={props.featuredBrands?.viewAll.text}
                  href={props.featuredBrands?.viewAll.url}
                  {...getAttributes(props.featuredBrands?.viewAll.attributes)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <>
        <Responsive breakpoints={['desktop', 'lg-desktop', 'xl-desktop']}>
          <CustomScrollbar className={styles['featured-brands-container']}>{brandSection}</CustomScrollbar>
        </Responsive>
        <Responsive breakpoints={['mobile', 'tablet']}>{brandSection}</Responsive>
      </>
    );
  }

  // function getPlaceholderData(section: IMenuSection) {
  //   section.links['t1'] = { node: '', isLink: true };
  //   section.links['t2'] = { node: '', isLink: true };
  //   section.links['t3'] = { node: '', isLink: true };
  //   section.links['t4'] = { node: '', isLink: true };
  // }
};
