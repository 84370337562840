import { BehaviorSubject, Subject } from 'rxjs';
import {
  CategoryPageDocument,
  CategoryPageQuery,
  CategoryPageQueryVariables,
  CategoryPageTitleDocument,
  CategoryPageTitleQuery,
  CategoryPageTitleQueryVariables,
  FeaturedBrandsDocument,
  FeaturedBrandsQuery,
  FeaturedBrandsQueryVariables,
  Level2Document,
  Level2Query,
  Level2QueryVariables,
} from '../generated/graphql';
import { IMenuRequest, IMenuResponse } from '../rs-emd-ui-modules/src/components/menus/product-menu/product-menu.dto';
import { IMenuService } from '../rs-emd-ui-modules/src/services/menu/menu-service';
import { graphQLService } from './graphql-service';

export class MenuService implements IMenuService {
  constructor(
    readonly menuVisibilitySubject = new BehaviorSubject<boolean>(false),
    readonly requestSubject = new Subject<IMenuRequest>(),
    readonly responseSubject = new Subject<IMenuResponse>()
  ) {
    this.watchRequestSubject();
  }

  watchRequestSubject() {
    this.requestSubject.subscribe((s) => {
      if (s.categoryId) this.getLevel2(s.categoryId);
      else if (s.categoryUrl) this.getCategories(s.categoryUrl);
      else if (s.getBrands) this.getFeaturedBrands();
    });
  }

  getLevel2(id: number) {
    graphQLService.apolloClient
      .query<Level2Query, Level2QueryVariables>({
        query: Level2Document,
        variables: { parentId: id },
      })
      .then((result) => {
        result.data && this.responseSubject.next({ menu: result.data.menu });
      });
  }

  getCategories(url: string) {
    graphQLService.apolloClient
      .query<CategoryPageQuery, CategoryPageQueryVariables>({
        query: CategoryPageDocument,
        variables: { slug: url },
      })
      .then((result) => {
        if (result.data) {
          if (result.data.categoryListPage.isSuccess) this.responseSubject.next({ categoryPage: result.data.categoryListPage });
          //redirect to redirectUrl
          else if (result.data.categoryListPage.redirectUrl.length > 0) {
            graphQLService.apolloClient
              .query<CategoryPageTitleQuery, CategoryPageTitleQueryVariables>({
                query: CategoryPageTitleDocument,
                variables: { slug: result.data.categoryListPage.redirectUrl.split('/category/')[1] },
              })
              .then((result2) => {
                if (result2.data && result2.data.categoryListPage.isSuccess)
                  this.responseSubject.next({
                    categoryPage: {
                      ...result.data.categoryListPage,
                      categoryName: result2.data.categoryListPage.categoryName,
                      description: result2.data.categoryListPage.description,
                    },
                  });
              });
          }
        }
      });
  }

  getFeaturedBrands() {
    graphQLService.apolloClient
      .query<FeaturedBrandsQuery, FeaturedBrandsQueryVariables>({
        query: FeaturedBrandsDocument,
      })
      .then((result) => {
        result.data && this.responseSubject.next({ menu: result.data.menu });
      });
  }
}

export const menuService = new MenuService();
