import React, { FunctionComponent } from 'react';
import { Error404 } from '../../rs-emd-ui-modules/src/components/error-pages';
import { useNotFoundErrorPage404Query } from '../../generated/graphql';
import { Loader } from 'rs-emd-ui-atoms';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';

export const Error404PageWrapper: FunctionComponent = () => {
  const { loading, error, data } = useNotFoundErrorPage404Query({
    variables: {},
    ssr: true,
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }

  //return <NoResults data={data.noResultsPage}></NoResults>;
  return <Error404 data={data.notFoundErrorPage}></Error404>;
};

export default Error404PageWrapper;
