import loadable from '@loadable/component';

const AddToBasketCTA = loadable(() => import(/* webpackChunkName: "shared" */ './add-to-basket-cta/add-to-basket-cta-component'), {
  resolveComponent: (components) => components.AddToBasketCTA,
});

const MetaDataAndTagging = loadable(() => import(/* webpackChunkName: "shared" */ './metadata-tagging/metadata-tagging-component'), {
  resolveComponent: (components) => components.MetaDataAndTagging,
});

const MasterHeader = loadable(() => import(/* webpackChunkName: "shared" */ './metadata-tagging/master-header-component'), {
  resolveComponent: (components) => components.MasterHeader,
});

const MasterBodyEnd = loadable(() => import(/* webpackChunkName: "shared" */ './metadata-tagging/master-body-end-component'), {
  resolveComponent: (components) => components.MasterBodyEnd,
});

const ConfirmationModal = loadable(
  () => import(/* webpackChunkName: "shared" */ './modals/confirmation-modal/confirmation-modal-component'),
  {
    resolveComponent: (components) => components.ConfirmationModal,
  }
);

const ProductInfoModal = loadable(
  () => import(/* webpackChunkName: "shared" */ './modals/product-info-modal/product-info-modal-component'),
  {
    resolveComponent: (components) => components.ProductInfoModal,
  }
);

const ProductInfoPill = loadable(() => import(/* webpackChunkName: "shared" */ './product-info-pill/product-info-pill-component'), {
  resolveComponent: (components) => components.ProductInfoPill,
});

const DTPStockBadge = loadable(() => import(/* webpackChunkName: "shared" */ './dtp-stock-badge/dtp-stock-badge-component'), {
  resolveComponent: (components) => components.DTPStockBadge,
});

const DTPStockInfo = loadable(() => import(/* webpackChunkName: "shared" */ './dtp-stock-info/dtp-stock-message-component'), {
  resolveComponent: (components) => components.DTPStockMessage,
});

const StockStatusCard = loadable(() => import(/* webpackChunkName: "shared" */ './stock-status-card/stock-status-card-component'), {
  resolveComponent: (components) => components.StockStatusCard,
});

const EnergyLabelProductInfo = loadable(
  () => import(/* webpackChunkName: "shared" */ './energy-label-product-info/energy-label-product-info-component'),
  {
    resolveComponent: (components) => components.EnergyLabelProductInfo,
  }
);

const SearchField = loadable(() => import(/* webpackChunkName: "shared" */ './search-field/search-field-component'), {
  resolveComponent: (components) => components.SearchField,
});

const Pagination = loadable(() => import(/* webpackChunkName: "shared" */ './page-controls/pagination/pagination-component'), {
  resolveComponent: (components) => components.Pagination,
});

const PageSizeControls = loadable(
  () => import(/* webpackChunkName: "shared" */ './page-controls/page-size-controls/page-size-controls-component'),
  {
    resolveComponent: (components) => components.PageSizeControls,
  }
);

const ShareProductsModal = loadable(
  () => import(/* webpackChunkName: "shared" */ './share-products-modal/share-products-modal-component'),
  {
    resolveComponent: (components) => components.ShareProductsModal,
  }
);

export {
  AddToBasketCTA,
  ConfirmationModal,
  DTPStockBadge,
  DTPStockInfo,
  EnergyLabelProductInfo,
  MasterBodyEnd,
  MasterHeader,
  MetaDataAndTagging,
  PageSizeControls,
  Pagination,
  ProductInfoModal,
  ProductInfoPill,
  SearchField,
  ShareProductsModal,
  StockStatusCard,
};
