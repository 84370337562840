import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useCompareAlternativesQuery } from '../../generated/graphql';
import { CompareAlternatives } from '../../rs-emd-ui-modules/src/components/compare-alternatives';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { basketService } from '../../services/basket-service';
import { plpService } from '../../services/plp-service';
import { pricingService } from '../../services/pricing-service';
import { productService } from '../../services/product-service';

export const CompareAlternativesWrapper: FunctionComponent = () => {
  let { stockCode } = useParams<{ stockCode: string }>();

  const { loading, error, data } = useCompareAlternativesQuery({
    variables: { stockCode: stockCode },
    ssr: true,
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (data && data.compareAlternativesPage.redirectUrl)
    return <RedirectWithStatus to={data.compareAlternativesPage.redirectUrl} statusCode={404} />;

  return (
    <CompareAlternatives
      data={data.compareAlternativesPage}
      basketService={basketService}
      plpService={plpService}
      productService={productService}
      pricingService={pricingService}></CompareAlternatives>
  );
};

export default CompareAlternativesWrapper;
