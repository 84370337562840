import React, { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomScrollbar, CustomScrollbarRef, Icon } from 'rs-emd-ui-atoms';
import { BehaviorSubject } from 'rxjs';
import { getAttributes } from '../../../../helpers/html.utils';
import { ILink } from '../../../../models/common/link.model';
import { IMenuItem } from '../../../../models/menu/menu-item.model';
import styles from './desktop-categories-component.module.scss';

type DesktopCategoriesProps = {
  shopAll?: ILink;
  categories?: IMenuItem[];
  shopAllLabel: string;
  resetMenuSelection?: boolean;
  menuSubject?: BehaviorSubject<boolean>;
  isNavigatingToPage?: () => void;
};

export const DesktopCategories: FunctionComponent<DesktopCategoriesProps> = (props: DesktopCategoriesProps) => {
  const [selectedCategory, setSelectedCategory] = useState<undefined | IMenuItem>(undefined);

  const ps = useRef<CustomScrollbarRef>(null);
  useLayoutEffect(() => {
    // call update scroll to re-calculate scroll length
    ps.current?.updateScroll();
  });

  //unset selected category when changing level 1 category
  useEffect(() => {
    if (props.shopAll) {
      setSelectedCategory(undefined);
    }
  }, [props.shopAll]);

  //unset selected category
  useEffect(() => {
    if (props.resetMenuSelection && selectedCategory) {
      setSelectedCategory(undefined);
    }
  }, [props.resetMenuSelection, selectedCategory]);

  return (
    <div className={styles['desktop-categories']}>
      <div
        className={`${styles['level-2-3']} ${styles['level-2']} ${
          styles[`cat-${props.shopAll?.attributes?.filter((a) => a.name === 'data-id')[0].value}`]
        }`}>
        {/** Level 2 section */}
        {!props.resetMenuSelection && (
          <CustomScrollbar showOnLoad={true} ref={ps}>
            <div className={styles['section-title']}>
              <span className={styles.title}>{props.shopAll?.text}</span>
              {props.shopAll && (
                <Link
                  to={props.shopAll.url}
                  {...getAttributes(props.shopAll.attributes)}
                  className={styles.link}
                  onClick={() => props.isNavigatingToPage && props.isNavigatingToPage()}>
                  {props.shopAllLabel}
                </Link>
              )}
            </div>
            <div className={styles.categories}>
              {props.categories &&
                props.categories.map((category) => {
                  return (
                    <div
                      className={`${styles.category} ${selectedCategory === category ? styles['selected-category'] : ''}`}
                      key={category.link.text}
                      onClick={() => setSelectedCategory(category)}>
                      <span>{category.link.text}</span>
                      <Icon className={styles['chev-right']} name='chev-right' width={24} height={24}></Icon>
                    </div>
                  );
                })}
            </div>
          </CustomScrollbar>
        )}
      </div>
      <div className={`${styles['level-2-3']} ${styles[`cat-${props.shopAll?.attributes?.filter((a) => a.name === 'data-id')[0].value}`]}`}>
        {/** Level 3 Section */}
        {selectedCategory && (
          <CustomScrollbar showOnLoad={true} ref={ps}>
            <div className={styles['section-title']}>
              <span className={styles.title}>{selectedCategory.link.text}</span>
              {props.shopAll && (
                <Link
                  to={selectedCategory.link.url}
                  {...getAttributes(selectedCategory.link.attributes)}
                  className={styles.link}
                  onClick={() => props.isNavigatingToPage && props.isNavigatingToPage()}>
                  {props.shopAllLabel}
                </Link>
              )}
            </div>
            <div className={styles.categories}>
              {selectedCategory.children &&
                selectedCategory.children.map((link) => {
                  return (
                    <Link
                      key={link.text}
                      to={link.url}
                      className={`${styles.category} ${styles['level-3-category']}`}
                      onClick={() => props.isNavigatingToPage && props.isNavigatingToPage()}>
                      {link.text}
                    </Link>
                  );
                })}
            </div>
          </CustomScrollbar>
        )}
      </div>
    </div>
  );
};
