import { ILabel } from '../../../models/common/label.model';
import { IProductDetailPage, IRecentSearch, ISuggestedSearch } from '../../../models/master/header/predictive-search.model';
import { IPredictiveSearchService } from '../../../services/predictive-search/predictive-search-service';
import {
  IGetRecentSearchesResponse,
  IGetSuggestedSearchesResponse,
  IProductDetailPagesResponse,
  IRemoveRecentSearchRequest,
} from '../../../services/predictive-search/predictive-search.dto';

export type SearchProps = {
  resetSearch?: () => void;
  searchString?: string;
  isClearClicked?: boolean;
  labels?: ILabel[];
  closeSearchModal?: boolean;
  toggleSearch: () => void;
  predictiveSearchService: IPredictiveSearchService;
};

export type State = {
  labels?: ILabel[];
  isClearVisible: boolean;
  recentSearches?: IRecentSearch[];
  suggestedSearchTerms?: ISuggestedSearch[];
  suggestedSearchProducts?: ISuggestedSearch[];
  productDetailPages?: IProductDetailPage[];
};

export enum SearchActionType {
  SetRecentSearches = 'SetRecentSearches',
  RemoveRecentSearch = 'RemoveRecentSearch',
  SetSuggestedSearches = 'SetSuggestedSearches',
  SetProductDetailPages = 'SetProductDetailPages',
  ResetSuggestions = 'ResetSuggestions',
}

export type Action =
  | { type: SearchActionType.SetRecentSearches; data: IGetRecentSearchesResponse }
  | { type: SearchActionType.RemoveRecentSearch; data: IRemoveRecentSearchRequest }
  | { type: SearchActionType.SetSuggestedSearches; data: IGetSuggestedSearchesResponse }
  | { type: SearchActionType.SetProductDetailPages; data: IProductDetailPagesResponse }
  | { type: SearchActionType.ResetSuggestions };
