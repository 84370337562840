import { ApolloLink } from '@apollo/client';
import { ErrorLink, onError } from '@apollo/client/link/error';
import { Request } from 'express';
import { getCookie } from '../rs-emd-ui-modules/src/helpers/html.utils';

export const IsSuccessLogging = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response?.data && response.data['isSuccess'] === false)
      fetch('/log', {
        method: 'POST',
        body: JSON.stringify({
          type: 'isSuccess',
          operation: operation,
          error: response,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

    return response;
  });
});

export const CSRErrorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
  if (process.env.NODE_ENV === 'development') {
    let query = {
      operatationName: operation.operationName,
      variables: operation.variables,
    };
    console.error(`GraphQL error on : ${JSON.stringify(query)}`, JSON.stringify(graphQLErrors ?? networkError));
  }

  let ignoreError = (process.env.REACT_APP_GRAPHQL_IGNORED_ERRORS ?? '').split('|').includes(operation.operationName);

  // ignore errors?
  if ((graphQLErrors && graphQLErrors?.length > 0) || networkError) {
    fetch('/log', {
      method: 'POST',
      body: JSON.stringify({
        type: graphQLErrors?.length ? 'graphQL' : 'network',
        operation: operation,
        error: graphQLErrors?.length ? graphQLErrors : networkError,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'X-Error-Type': ignoreError ? 'warn' : '',
      },
    });

    if (ignoreError && response) response.errors = undefined;
    else if ((graphQLErrors && graphQLErrors?.length > 0) || networkError)
      window.location.href = `${process.env.REACT_APP_ERROR_ENDPOINT}?sessionId=${getCookie('ASP.NET_SessionId')}`;
  }
});

export class SSRErrorLink extends ErrorLink {
  private req: Request;
  constructor(req: Request) {
    super(({ graphQLErrors, networkError, response, operation, forward }) => {
      let error: any = graphQLErrors ?? networkError;
      error.res = response;
      error.query = {
        operationName: operation.operationName,
        variables: operation.variables,
        error: graphQLErrors ?? networkError ?? response,
      };

      if (error) {
        this.req.log.error(error, 'GraphQL error');
      }
    });

    this.req = req;
  }
}
