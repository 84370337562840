import React, {
  CSSProperties,
  ChangeEvent,
  FunctionComponent,
  MouseEvent,
  ReactText,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Radio, Responsive, Switch, isMobileDevice, isTabletDevice } from 'rs-emd-ui-atoms';
import { BehaviorSubject, Subject } from 'rxjs';
import { getAttributes, getLabel } from '../../../helpers/html.utils';
import { setSiteCurrency, setSiteLanguage } from '../../../helpers/site-settings.utils';
import { getCurrencyCookie, getLanguageCookie, includeVat, setVatCookie } from '../../../helpers/user-settings.utils';
import { ILink } from '../../../models/common/link.model';
import { ISiteSettingsCurrency } from '../../../models/master/site-settings-currency.model';
import { ISiteSettingsLanguage } from '../../../models/master/site-settings-language.model';
import { ISiteSettings } from '../../../models/master/site-settings.model';
import { IMenuItem } from '../../../models/menu/menu-item.model';
import { IMenu } from '../../../models/menu/menu.model';
import { IPredictiveSearchService } from '../../../services/predictive-search/predictive-search-service';
import { CategoryListPage } from '../../category-list-page';
import { DesktopCategories } from './desktop-categories/desktop-categories-component';
import { FeaturedBrands } from './featured-brands/featured-brands-component';
import { MenuLevel } from './menu-level/menu-level-component';
import { Action, MenuRequestAction, MenuType, State } from './product-menu-component.model';
import styles from './product-menu-component.module.scss';
import { IMenuRequest, IMenuResponse } from './product-menu.dto';
import { ILinkNode, IMenuSection } from './product-menu.vm';

type ProductMenuProps = {
  data: IMenu;
  siteSettings: ISiteSettings;
  menuSubject?: BehaviorSubject<boolean>;
  requestSubject?: Subject<IMenuRequest>;
  responseSubject?: Subject<IMenuResponse>;
  clickEvent: (eventDataAttributes: any) => void;
  predictiveSearchService: IPredictiveSearchService;
};

export const ProductMenu: FunctionComponent<ProductMenuProps> = (props: ProductMenuProps) => {
  let menuContainerRef = useRef<HTMLDivElement>(null);

  let intialLevel1M = getLevel1();

  const [state, dispatch] = useReducer(reducer, {
    levelVisibility: {
      '1': true,
    },
    level: 1,
    isOpen: props.menuSubject ? false : true,
    preRenderedLevels: {
      level1M: intialLevel1M,
      level1D: getLevel1Desktop(),
    },
    defaultLang: props.siteSettings.languages.find((l) => l.isSelected) ?? props.siteSettings.languages[0],
    defaultCurrency: props.siteSettings.currencies[0],
    isGoingBackToLevel3: false,
  });

  const [isVATSelected, setIsVATSelected] = useState<boolean>(false);
  const [pageWidth, setPageWidth] = useState<number>();

  // set page width
  useLayoutEffect(() => {
    setPageWidth(window.document.body.clientWidth);
  }, []);

  // set sitesettings
  useLayoutEffect(() => {
    let selectedLang: ISiteSettingsLanguage | undefined = undefined;
    let selectedCurrency: ISiteSettingsCurrency | undefined = undefined;

    if (props.siteSettings.showLanguages || props.siteSettings.showCurrencies) {
      // Default Lang
      if (props.siteSettings.showLanguages) {
        const selectedLangId = getLanguageCookie();

        //check if selected language exists in props
        if (selectedLangId) {
          selectedLang = props.siteSettings.languages.find((l) => l.countryCodeISO2 === selectedLangId);
        }
      }

      if (props.siteSettings.showCurrencies) {
        // Default currency
        let selectedCurrencyId = getCurrencyCookie();

        //check if selected currency exists in props
        if (selectedCurrencyId) {
          selectedCurrency = props.siteSettings.currencies.find((c) => c.id === Number(selectedCurrencyId));
        }
      }

      dispatch({
        type: 'setDefaultSiteSettings',
        currency: selectedCurrency,
        language: selectedLang,
      });
    }
  }, [props.siteSettings.currencies, props.siteSettings.languages, props.siteSettings.showCurrencies, props.siteSettings.showLanguages]);

  // on resize
  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };

    function onResize() {
      setPageWidth(window.document.body.clientWidth);
    }
  }, []);

  useLayoutEffect(() => {
    props.menuSubject?.subscribe((isOpen) => {
      dispatch({ type: 'show', open: isOpen });
    });

    props.responseSubject?.subscribe((res) => {
      dispatch({ type: 'set', data: res.menu, categoryPage: res.categoryPage });
      dispatch({ type: 'up', level: res.menu?.categories ? 2 : res.categoryPage ? 4 : 5 });
    });
    return () => {
      // Cleanup & unsubscribe
      props.menuSubject?.unsubscribe();
      props.responseSubject?.unsubscribe();
    };
  }, [props.menuSubject, props.responseSubject]);

  //-- Set VAT value --//
  useLayoutEffect(() => {
    setIsVATSelected(includeVat());
  }, []);

  //to re-calculate menu width
  useEffect(() => {
    function onResize() {
      document.documentElement.style.setProperty('--menu-width', menuContainerRef.current?.clientWidth + 'px');
    }

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [menuContainerRef]);

  return (
    <React.Fragment>
      <div
        className={`${styles['product-menu-container']} ${!state.isOpen ? 'hide' : ''} page-content`}
        style={{ '--menu-width': menuContainerRef.current?.clientWidth + 'px' } as CSSProperties}>
        <div ref={menuContainerRef} className={`page-content ${styles['menu-container']}`}>
          {/* Content */}
          <div className={`${styles.content} ${state.level === 5 ? styles.brands : ''}`}>
            {/* Mobile */}
            <Responsive breakpoints={['mobile', 'tablet']}>
              {/* Level 1 - should always be rendered */}
              {state.levelVisibility['1'] && (
                <>
                  <MenuLevel
                    level={1}
                    sections={state.preRenderedLevels.level1M}
                    cardClickCallback={onShowLevelClick}
                    isMobile={true}
                    shopAllLabel={getLabel(props.data.labels, 'shop_all')}
                    menuSubject={props.menuSubject}></MenuLevel>

                  {/* VAT */}
                  {isMobileDevice() && (
                    <div className={styles.vat}>
                      <p className={isVATSelected ? styles.unselected : ''}>{getLabel(props.data.labels, 'ex_vat')}</p>
                      <Switch id='switch-vat-header-menu' checkedValue={isVATSelected} onChange={(e) => setVat(e)}></Switch>
                      <p className={!isVATSelected ? styles.unselected : ''}>{getLabel(props.data.labels, 'inc_vat')}</p>
                    </div>
                  )}
                </>
              )}

              {/* Level 2/3 */}
              {(state.level === 2 || state.level === 3) && (
                <MenuLevel
                  labels={props.data.labels}
                  level={state.level}
                  sections={getLevel(undefined, undefined, undefined, `cat-${state.currentLevel2Category}`)}
                  cardClickCallback={onShowLevelClick}
                  isMobile={true}
                  shopAllLabel={getLabel(props.data.labels, 'shop_all')}
                  menuSubject={props.menuSubject}></MenuLevel>
              )}

              {/** Category */}
              {state.levelVisibility['categories'] && state.categoryPageData && (
                <div className={`${styles['settings-menu']} ${styles['settings-menu-categories']}`}>
                  <div className={styles['section-title']} onClick={(e) => onShowLevelClick(e, 3, false)}>
                    <Icon name='chev-left' width={24} height={24} className={styles.back}></Icon>
                    <div className={styles['parent-category']}>{state.categoryPageData.categoryName}</div>
                  </div>
                  <CategoryListPage
                    data={state.categoryPageData}
                    showBreadcrumbs={false}
                    showTitle={false}
                    isInMenu={true}
                    onLevelClick={onCloseMenuClick}
                    predictiveSearchService={props.predictiveSearchService}
                  />
                </div>
              )}

              {/* Brands*/}
              {state.levelVisibility['brands'] && (
                <FeaturedBrands
                  cardClickCallback={onShowLevelClick}
                  featuredBrands={state.innerMenuData?.featuredBrands}
                  parentLabel={getLabel(props.data.labels, 'brands')}
                  featuredBrandsLabel={getLabel(props.data.labels, 'featured_brands')}></FeaturedBrands>
              )}

              {/* Currencies*/}
              {state.levelVisibility['currencies'] && props.siteSettings.showCurrencies && (
                <div className={styles['settings-menu']}>
                  <div
                    className={styles['section-title']}
                    onClick={(e) => {
                      onShowLevelClick(e, 1, false);

                      //reset since default selection for radio is the default currency
                      dispatch({
                        type: 'setSelectedCurrency',
                        data: state.defaultCurrency,
                      });
                    }}>
                    <Icon name='chev-left' width={24} height={24} className={styles.back}></Icon>
                    <div className={styles['parent-category']}>{getLabel(props.data.labels, 'currency')}</div>
                  </div>
                  <Radio
                    name='currencies'
                    layout='column'
                    spacing={1.6}
                    class={styles.radio}
                    defaultSelection={state.defaultCurrency.id}
                    selectCallback={(key) =>
                      dispatch({
                        type: 'setSelectedCurrency',
                        data: props.siteSettings.currencies.find((c) => c.id === key) ?? state.defaultCurrency,
                      })
                    }
                    list={props.siteSettings.currencies.map((c) => {
                      return {
                        key: c.id,
                        elt: <span>{c.displayName}</span>,
                      };
                    })}
                  />
                  <Button
                    text={getLabel(props.data.labels, 'apply')}
                    onClick={() =>
                      setSiteCurrency(props.siteSettings.currencies, state.selectedCurrency?.id ?? state.defaultCurrency.id)
                    }></Button>
                </div>
              )}

              {/* Languages*/}
              {state.levelVisibility['languages'] && props.siteSettings.showLanguages && (
                <div className={styles['settings-menu']}>
                  <div
                    className={styles['section-title']}
                    onClick={(e) => {
                      onShowLevelClick(e, 1, false);

                      //reset since default selection for radio is the default language
                      dispatch({
                        type: 'setSelectedLang',
                        data: state.defaultLang,
                      });
                    }}>
                    <Icon name='chev-left' width={24} height={24} className={styles.back}></Icon>
                    <div className={styles['parent-category']}>{getLabel(props.data.labels, 'language')}</div>
                  </div>
                  <Radio
                    name='languages'
                    layout='column'
                    spacing={1.6}
                    class={styles.radio}
                    defaultSelection={state.defaultLang.countryCodeISO2}
                    selectCallback={(key) =>
                      dispatch({
                        type: 'setSelectedLang',
                        data: props.siteSettings.languages.find((l) => l.countryCodeISO2 === key) ?? state.defaultLang,
                      })
                    }
                    list={props.siteSettings.languages.map((l) => {
                      return {
                        key: l.countryCodeISO2,
                        elt: <span>{l.name}</span>,
                      };
                    })}
                  />
                  <Button
                    text={getLabel(props.data.labels, 'apply')}
                    onClick={() => setLanguage(state.selectedLang?.countryCodeISO2 ?? state.defaultLang.countryCodeISO2)}></Button>
                </div>
              )}
            </Responsive>

            {/* Desktop */}
            <Responsive breakpoints={['desktop', 'lg-desktop']}>
              {/* Level 1 - should always be rendered */}
              <MenuLevel
                level={1}
                sections={state.preRenderedLevels.level1D}
                cardClickCallback={onShowLevelClick}
                activeCategory={state.currentCategory}
                categoryLinkEnabled={true}
                shopAllLabel={getLabel(props.data.labels, 'shop_all')}></MenuLevel>
            </Responsive>
          </div>

          {/* Desktop Level 2/3 */}
          <Responsive breakpoints={['desktop', 'lg-desktop']}>
            {state.level !== 5 && (
              <DesktopCategories
                categories={state.innerMenuData?.categories}
                shopAll={getParentCategory(2)?.link}
                shopAllLabel={getLabel(props.data.labels, 'shop_all')}
                resetMenuSelection={state.currentCategory === undefined}
                menuSubject={props.menuSubject}
                isNavigatingToPage={onCloseMenuClick}></DesktopCategories>
            )}

            {/* Brands*/}
            {state.level === 5 && (
              <FeaturedBrands
                cardClickCallback={onShowLevelClick}
                featuredBrands={state.innerMenuData?.featuredBrands}
                parentLabel={getLabel(props.data.labels, 'brands')}
                featuredBrandsLabel={getLabel(props.data.labels, 'featured_brands')}></FeaturedBrands>
            )}
          </Responsive>
        </div>
        <div className={styles.overlay} style={{ width: pageWidth }} onClick={onCloseMenuClick}></div>
      </div>
    </React.Fragment>
  );

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'get': {
        getNextLevel(action);
        return {
          ...state,
          level: action.level,
          levelVisibility: updateVisibility(state, action.level),
          currentCategory: action.categoryId,
          innerMenuData: undefined,
          currentLevel2Category: action.level === 4 ? state.currentLevel2Category : action.categoryId,
          currentLevel3Category: undefined,
        };
      }
      case 'getCategories': {
        getNextLevel(action);
        return {
          ...state,
          levelVisibility: updateVisibility(state, 1, MenuType.Categories),
          currentCategory: 'categories',
          currentLevel3Category: action.level3CategoryId,
          level: 4,
          categoryPageData: undefined,
        };
      }
      case 'getBrands': {
        getNextLevel(action);
        return {
          ...state,
          levelVisibility: updateVisibility(state, 1, MenuType.Brands),
          currentCategory: 'brands',
          level: 5,
        };
      }
      case 'showCurrencies': {
        getNextLevel(action);
        return {
          ...state,
          levelVisibility: updateVisibility(state, 1, MenuType.Currencies),
          currentCategory: 'currencies',
          level: 5,
        };
      }
      case 'showLanguages': {
        getNextLevel(action);
        return {
          ...state,
          levelVisibility: updateVisibility(state, 1, MenuType.Languages),
          currentCategory: 'languages',
          level: 5,
        };
      }
      case 'set': {
        return {
          ...state,
          innerMenuData: action.data ?? state.innerMenuData,
          categoryPageData: action.categoryPage,
        };
      }
      case 'up':
      case 'down':
        return {
          ...state,
          level: action.level,
          levelVisibility: updateVisibility(
            state,
            action.level,
            action.level === 4 ? MenuType.Categories : action.level === 5 ? MenuType.Brands : MenuType.Level
          ),
          currentCategory: action.categoryId ?? state.currentCategory,
          isGoingBackToLevel3: false,
        };
      case 'show': {
        return {
          ...state,
          isOpen: action.open,
          currentCategory: undefined,
          levelVisibility: {
            1: true,
          },
          level: 1,
        };
      }
      case 'setDefaultSiteSettings': {
        let lang = action.language ?? state.defaultLang;
        let currency = action.currency ?? state.defaultCurrency;
        return {
          ...state,
          defaultLang: lang,
          defaultCurrency: currency,
          preRenderedLevels: {
            ...state.preRenderedLevels,
            level1M: getLevel1(lang.name, currency.displayName),
          },
        };
      }
      case 'setSelectedLang': {
        return {
          ...state,
          selectedLang: action.data,
        };
      }
      case 'setSelectedCurrency': {
        return {
          ...state,
          selectedCurrency: action.data,
        };
      }
      case 'goBackToLevel3': {
        return {
          ...state,
          level: 3,
          levelVisibility: updateVisibility(state, 3),
          currentCategory: state.currentLevel3Category,
          isGoingBackToLevel3: true,
        };
      }
    }
  }

  function onShowLevelClick(
    e: MouseEvent,
    level: number,
    open?: boolean,
    categoryLinkEnabled?: boolean,
    categoryUrl?: string,
    linkNode?: ILinkNode
  ) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (!categoryLinkEnabled) {
      e.preventDefault();
    } else {
      onCloseMenuClick();
    }
    let currentId = e.currentTarget.id;

    let eInfo = e.currentTarget.querySelector('a')?.getAttribute('data-clickEvent-info');
    let eResult = e.currentTarget.querySelector('a')?.getAttribute('data-clickEvent-result');

    let clickData =
      eInfo && eResult
        ? {
            __Info: eInfo,
            __Result: eResult,
          }
        : undefined;

    //-- need to get data from service? --//
    if (level === 3 && state.level === 4) {
      dispatch({ type: 'goBackToLevel3' });
    } else if (!isNaN(Number(currentId)) || currentId.startsWith('cat-') || currentId === 'brands') {
      if ((level === 2 && state.level !== 3) || currentId.startsWith('cat-') || currentId === 'brands') {
        let action: MenuRequestAction = { type: 'get', categoryId: Number(currentId), level: level, clickData: clickData };
        if (e.currentTarget.id === 'brands') {
          action = { type: 'getBrands' };
        } else if (e.currentTarget.id.startsWith('cat-')) {
          action = { type: 'getCategories', url: categoryUrl ?? '', level3CategoryId: (state.currentCategory ?? '').toString() };
        }
        if (e.type === 'click') {
          dispatch(action);
        }
      } else if (e.type === 'click') {
        dispatch({ level: level, type: open ? 'up' : 'down', categoryId: currentId });
      }
    } else if (currentId === 'language') {
      dispatch({ type: 'showLanguages' });
    } else if (currentId === 'currency') {
      dispatch({ type: 'showCurrencies' });
    } else {
      dispatch({ level: 1, type: 'down', categoryId: -1 });
    }
  }

  function getNextLevel(action: MenuRequestAction) {
    if (action.type === 'get') {
      props.requestSubject?.next({ categoryId: action.categoryId });

      if (action.clickData) {
        props.clickEvent(action.clickData);
      }
    } else if (action.type === 'getCategories') {
      props.requestSubject?.next({ categoryUrl: action.url });
    } else if (action.type === 'getBrands') {
      props.requestSubject?.next({ getBrands: true });
    }
  }

  function onCloseMenuClick() {
    props.menuSubject?.next(false);
  }

  function updateVisibility(state: State, level: number, menuType: MenuType = MenuType.Level) {
    let visibility = state.levelVisibility;
    let levelToShow: string = menuType === 'Level' ? level.toString() : menuType.toLowerCase();

    if (levelToShow !== undefined) {
      visibility[levelToShow] = true; // in case current level visibility is not configured
      Object.keys(visibility).forEach(function (key) {
        visibility[key] = key === levelToShow; // hide previous levels and show current one
      });
    }

    return visibility;
  }

  function getLevel1(defaultLang?: string, defaultCurrency?: string): IMenuSection[] {
    let shopLabels: { [key: string]: ILinkNode } = {
      ...getLevelCategories(1),
      brands: { node: <span>{getLabel(props.data.labels, 'brands')}</span> },
    };

    props.data.menuLinks?.forEach((ml) => {
      shopLabels[ml.text] = getLinkNode(ml);
    });

    let otherLinkLabels: { [key: string]: ILinkNode } = {};
    props.data.otherLinks?.forEach((ol) => {
      otherLinkLabels[ol.text] = getLinkNode(ol);
    });

    //check which site settings should be visible
    let siteSettingsLabels: { [key: string]: ILinkNode } = {};

    if (props.siteSettings.showLanguages) {
      siteSettingsLabels.language = {
        node: (
          <span className={'site-settings'}>
            <p className={'setting'}>{getLabel(props.data.labels, 'language')}</p>
            <p className={'selected-setting'}>{defaultLang}</p>
          </span>
        ),
      };
    }

    if (props.siteSettings.showCurrencies) {
      siteSettingsLabels.currency = {
        node: (
          <span className={'site-settings'}>
            <p className={'setting'}>{getLabel(props.data.labels, 'currency')}</p>
            <p className={'selected-setting'}>{defaultCurrency}</p>
          </span>
        ),
      };
    }

    //prepare return data
    let level0Section: IMenuSection[] = [
      {
        title: getLabel(props.data.labels, 'shop_by'),
        links: shopLabels,
        className: 'shop-section',
      },
      {
        title: getLabel(props.data.labels, 'something_else'),
        links: otherLinkLabels,
        className: 'link-section',
      },
    ];

    //only return site settings labels if any of them are required to be displayed
    if (Object.keys(siteSettingsLabels).length !== 0) {
      level0Section = level0Section.concat({
        title: getLabel(props.data.labels, 'site_settings'),
        links: siteSettingsLabels,
        className: 'site-settings-section',
      });
    }
    //in order to show site settings section title for vat toggle
    else if (props.siteSettings.showVATToggle) {
      level0Section = level0Section.concat({
        title: getLabel(props.data.labels, 'site_settings'),
        links: {},
        className: 'site-settings-section',
        containsVATToggleonly: true,
      });
    }

    return level0Section;
  }

  function getLevel(level?: number, title?: string, description?: string, className?: string): IMenuSection[] {
    let parent: IMenuItem | undefined;
    if ((level ?? state.level) > 1) {
      parent = getParentCategory();
    }

    return [
      {
        title: title ?? parent?.link.text,
        description: description ?? parent?.description,
        shopAll: (level ?? state.level) > 1 ? parent?.link : undefined,
        links: getLevelCategories(level ?? state.level),
        className: className ?? '',
      },
    ];
  }

  function getParentCategory(level?: number): IMenuItem | undefined {
    level = level ?? state?.level;

    return getCategoriesData(true, level)?.filter((c) => {
      let x = c.link.attributes?.filter((a) => a.value === (level === 2 ? state.currentLevel2Category : state.currentCategory)?.toString());
      return x !== undefined ? x.length > 0 : false;
    })[0];
  }

  function getLevel1Desktop() {
    let categories = getLevel(1, getLabel(props.data.labels, 'shop_by'), undefined, 'shop-section');
    categories[0].links.brands = { node: getLabel(props.data.labels, 'brands') };

    props.data.menuLinks?.forEach((ml) => {
      categories[0].links[ml.text] = getLinkNode(ml);
    });

    return categories;
  }

  function getCategoriesData(isPreviousLevel: boolean = false, currLevel?: number): IMenuItem[] | undefined {
    if (currLevel || state?.level) {
      currLevel = currLevel ?? state.level;
      // need previous level's data?
      let level = isPreviousLevel ? currLevel - 1 : currLevel;

      if (level === 1) {
        return props.data.categories;
      } else if (level > 1) {
        return state.innerMenuData?.categories;
      }
    }
  }

  function getLevelCategories(level: number): { [key: string]: ILinkNode } {
    let categories: { [key: string]: ILinkNode } = {};
    let categoriesData = getCategoriesData(false, level);

    if (level !== 3) {
      categoriesData &&
        categoriesData.forEach((c) => {
          if (c.link.attributes) {
            let id = c.link.attributes?.filter((l) => l.name === 'data-id')[0].value;
            categories['cat-' + id] = getLinkNode(c.link, false, (e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
              if (isMobileDevice() || isTabletDevice()) e.preventDefault();
            });
          }
        });
    } else {
      // level 3: different data structure
      let currentCat = categoriesData?.filter((c) => c.link.attributes?.some((a) => a.value === state.currentCategory?.toString()))[0];

      currentCat?.children?.map((l) => {
        if (l.attributes) {
          let id = l.attributes?.filter((a) => a.name === 'data-id')[0].value;
          categories[id] = getLinkNode(l);
        }
      });
    }

    return categories;
  }

  function setVat(e: ChangeEvent<HTMLInputElement>) {
    var checked = e.target.checked;
    setVatCookie(checked);
  }

  function setLanguage(key: ReactText) {
    //redirects if there is a change in the language
    setSiteLanguage(props.siteSettings.languages, key);

    //close mobile modal if selected language is the same as that already selected
    onCloseMenuClick();
  }

  function getLinkNode(
    link: ILink,
    isLink: boolean = true,
    clickEvent?: (e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => void
  ): ILinkNode {
    const icon = link.attributes?.find((a) => a.name === 'data-icon');
    const isExternalLink = link.attributes?.find((a) => a.name === 'isExternalLink')?.value === 'true';
    const attributes = link.attributes?.filter((a) => a.name !== 'isExternalLink');

    const innerFragment = (
      <>
        <span>{link.text}</span>
        {icon ? <Icon name={icon.value}></Icon> : undefined}
      </>
    );

    return {
      node: (
        <>
          {!isExternalLink && (
            <Link
              to={link.url}
              {...getAttributes(attributes)}
              onClick={(e) => {
                clickEvent && clickEvent(e);
              }}>
              {innerFragment}
            </Link>
          )}
          {isExternalLink && (
            <a
              href={link.url}
              {...getAttributes(attributes)}
              onClick={(e) => {
                clickEvent && clickEvent(e);
              }}>
              {innerFragment}
            </a>
          )}
        </>
      ),
      isLink: isLink,
      clickEvent: {
        result: link.attributes?.filter((a) => a.name === 'data-clickEvent-result')[0]?.value,
        info: link.attributes?.filter((a) => a.name === 'data-clickEvent-info')[0]?.value,
      },
    };
  }
};
