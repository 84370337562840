import { isMobileDevice, isTabletDevice } from 'rs-emd-ui-atoms';
import { ISearchSelector, ISearchSort } from './../rs-emd-ui-modules/src/models/plp/input/search-selector.model';
import {
  IFilterTotalProductsRequest,
  IPLPStockResult,
  IPreviewFilterTotalProductsResult,
  IUpdateProductsPage,
} from './../rs-emd-ui-modules/src/services/plp/plp.dto';
import { IPLPService } from '../rs-emd-ui-modules/src/services/plp/plp-service';
import { IFilterProductsResult, IFilterRequest, IPreviewFilterResult } from '../rs-emd-ui-modules/src/services/plp/plp.dto';
import {
  FilterProductsAndFacetsDocument,
  FilterProductsAndFacetsQuery,
  FilterProductsAndFacetsQueryVariables,
  FilterProductsDocument,
  FilterProductsQuery,
  FilterProductsQueryVariables,
  GetPlpStockDocument,
  GetPlpStockQuery,
  GetPlpStockQueryVariables,
  GetProductsDocument,
  GetProductsQuery,
  GetProductsQueryVariables,
  PreviewFiltersDocument,
  PreviewFiltersQuery,
  PreviewFiltersQueryVariables,
  PreviewFiltersTotalProductsQuery,
  PreviewFiltersTotalProductsQueryVariables,
} from './../generated/graphql';
import { graphQLService } from './graphql-service';
import { pushEvent } from '../tagging/ensighten';
import { PreviewFiltersTotalProducts } from '../graphql/queries/product-list-page.query';

export class PLPService implements IPLPService {
  private getTagName() {
    return new URLSearchParams(window.location.search).get('tag');
  }

  async getPreviewFilters(variables: IFilterRequest): Promise<IPreviewFilterResult> {
    const result = await graphQLService.apolloClient.query<PreviewFiltersQuery, PreviewFiltersQueryVariables>({
      query: PreviewFiltersDocument,
      variables: { id: variables.id, searchQuery: variables.searchQuery, selector: variables.selector, tagName: this.getTagName() },
      fetchPolicy: 'no-cache',
    });

    let res: IPreviewFilterResult = {};
    if (result.data) {
      res.facetArea = result.data.productListPage.facetsArea;
      res.summary = result.data.productListPage.summary;
    }
    return res;
  }

  async getPreviewFiltersTotalProducts(variables: IFilterTotalProductsRequest): Promise<IPreviewFilterTotalProductsResult> {
    const result = await graphQLService.apolloClient.query<PreviewFiltersTotalProductsQuery, PreviewFiltersTotalProductsQueryVariables>({
      query: PreviewFiltersTotalProducts,
      variables: { slug: variables.slug, selector: variables.selector },
    });

    let res: IPreviewFilterTotalProductsResult = {};
    if (result.data) {
      res.summary = result.data.productListPage.summary;
    }
    return res;
  }

  async getFilteredProducts(variables: IFilterRequest): Promise<IFilterProductsResult> {
    const result = await graphQLService.apolloClient.query<FilterProductsQuery, FilterProductsQueryVariables>({
      query: FilterProductsDocument,
      variables: {
        id: variables.id,
        searchQuery: variables.searchQuery,
        tagName: this.getTagName(),
        selector: variables.selector,
        first: variables.first,
        sort: variables.sort,
      },
      fetchPolicy: 'no-cache',
    });

    let res: IFilterProductsResult = {};
    if (result.data) {
      res.productsArea = result.data.productListPage.productsArea;
      res.facetArea = result.data.productListPage.facetsArea;
      res.categoriesArea = result.data.productListPage.categoriesArea;

      this.setUrlHash(variables.history, variables.currentHash, variables.first, variables.offset, variables.selector, variables.sort);

      // tagging: push filter event
      this.updateTagging(result.data);
    }
    return res;
  }

  async getFilteredProductsAndFacets(variables: IFilterRequest): Promise<IFilterProductsResult> {
    const result = await graphQLService.apolloClient.query<FilterProductsAndFacetsQuery, FilterProductsAndFacetsQueryVariables>({
      query: FilterProductsAndFacetsDocument,
      variables: {
        id: variables.id,
        searchQuery: variables.searchQuery,
        tagName: this.getTagName(),
        selector: variables.selector,
        first: variables.first,
        offset: variables.offset,
        sort: variables.sort,
      },
      fetchPolicy: 'no-cache',
    });

    let res: IFilterProductsResult = {};
    if (result.data) {
      res.productsArea = result.data.productListPage.productsArea;
      res.facetArea = result.data.productListPage.facetsArea;
      res.categoriesArea = result.data.productListPage.categoriesArea;
      res.summary = result.data.productListPage.summary;

      this.setUrlHash(variables.history, variables.currentHash, variables.first, variables.offset, variables.selector, variables.sort);

      // tagging: push filter event
      this.updateTagging(result.data);
    }
    return res;
  }

  async getProducts(variables: IFilterRequest): Promise<IUpdateProductsPage> {
    const result = await graphQLService.apolloClient.query<GetProductsQuery, GetProductsQueryVariables>({
      query: GetProductsDocument,
      variables: {
        id: variables.id,
        searchQuery: variables.searchQuery,
        tagName: this.getTagName(),
        selector: variables.selector,
        first: variables.first,
        offset: variables.offset,
        sort: variables.sort,
      },
      fetchPolicy: 'no-cache',
    });

    let res: IUpdateProductsPage = {};
    if (result.data) {
      res.productsArea = result.data.productListPage.productsArea;

      this.setUrlHash(variables.history, variables.currentHash, variables.first, variables.offset, variables.selector, variables.sort);
    }
    return res;
  }

  setUrlHash(
    history: any,
    currHash?: string,
    first?: number,
    offset?: number,
    selector?: ISearchSelector,
    sort?: ISearchSort,
    categoryPageUrl?: string
  ) {
    let hash: string = '';

    if (first || offset || selector || sort) {
      hash = '#';
      let defaultPageSize = +(process.env.REACT_APP_PLP_DEFUALT_PAGE_SIZE ?? 20);

      //pagesize
      if (first && first > defaultPageSize)
        // optimisation to avoid extra query
        hash = `${hash}ps=${first};`;

      //offset (determines pg #)
      if (offset) {
        hash = `${hash}po=${offset};`;
      }

      // applied facets
      if (selector && selector.facets.length) {
        let facets = selector.facets
          .map((f) => {
            return `${f.facetId}_${f.facetValueId}`;
          })
          .join();
        hash = `${hash}af=${facets};`;
      }

      // sort
      if (sort) {
        hash = `${hash}sf=${sort.sortField};sd=${sort.direction};`;
      }
    }

    // categoryPageURL is populated when function called from PDP page for similar products
    // window.history.pushState({}, '', hash);

    if (currHash !== hash)
      history.push({
        pathname: categoryPageUrl ?? history.location.pathname,
        search: categoryPageUrl ? undefined : history.location.search,
        hash,
        state: { prevPath: history.location.pathname },
      });
  }

  updateTagging(data: FilterProductsAndFacetsQuery | FilterProductsQuery) {
    // tagging: push filter event
    let attributeValues: any[] = [];
    let facetValues: any[] = [];

    // facet values
    data.productListPage.facetsArea?.appliedFilters.forEach((facet) => {
      facetValues.push({
        id: facet.id,
        name: facet.displayName,
      });

      // attribute values
      facet.values.forEach((filter) => {
        attributeValues.push({
          id: filter.id,
          name: filter.displayValue,
        });
      });
    });

    pushEvent('applyFilterDataBlockEvent', {
      attribute_values: attributeValues,
      facets_applies: facetValues,
      no_matches: data.productListPage.summary?.totalProducts,
      button_location: isMobileDevice() || isTabletDevice() ? 'facetModel' : 'automaticFilter',
    });
  }

  tagSRPFamilySelection(familyId?: string | number) {
    pushEvent('searchresult.click', {
      search_result_clicked: familyId,
      search_result_type_clicked: 'category',
    });
  }

  async getPLPStock(variables: IFilterRequest): Promise<IPLPStockResult> {
    const result = await graphQLService.apolloClient.query<GetPlpStockQuery, GetPlpStockQueryVariables>({
      query: GetPlpStockDocument,
      variables: {
        id: variables.id,
        searchQuery: variables.searchQuery,
        tagName: this.getTagName(),
        selector: variables.selector,
        first: variables.first,
        offset: variables.offset,
        sort: variables.sort,
      },
      fetchPolicy: 'no-cache',
    });

    let res: IPLPStockResult = {};
    if (result?.data) {
      res.products = result.data.productListPage.productsArea?.products;
    }
    return res;
  }
}

export const plpService = new PLPService();
