import loadable from '@loadable/component';

const AccountForm = loadable(() => import(/* webpackChunkName: "order-confirmation" */ './account-form/account-form-component'), {
  resolveComponent: (components) => components.AccountForm,
});

const BankDetail = loadable(() => import(/* webpackChunkName: "order-confirmation" */ './bank-detail/bank-detail-component'), {
  resolveComponent: (components) => components.BankDetail,
});

const NextSteps = loadable(() => import(/* webpackChunkName: "order-confirmation" */ './next-steps/next-steps-component'), {
  resolveComponent: (components) => components.NextSteps,
});

const OrderConfirmation = loadable(
  () => import(/* webpackChunkName: "order-confirmation" */ './order-confirmation/order-confirmation-component'),
  {
    resolveComponent: (components) => components.OrderConfirmation,
  }
);

const OrderConfirmationHeader = loadable(
  () => import(/* webpackChunkName: "order-confirmation" */ './order-confirmation-header/order-confirmation-header-component'),
  {
    resolveComponent: (components) => components.OrderConfirmationHeader,
  }
);

const SendOrderCard = loadable(() => import(/* webpackChunkName: "order-confirmation" */ './send-order-card/send-order-card-component'), {
  resolveComponent: (components) => components.SendOrderCard,
});

export { AccountForm, BankDetail, NextSteps, OrderConfirmation, OrderConfirmationHeader, SendOrderCard };
