import { ILabel } from '../common/label.model';
import { IHtmlTag } from '../common/metadata-tagging/html-tag.model';
import { IMenu } from '../menu/menu.model';
import { IFooter } from './footer/footer.model';
import { IHeaderMenu } from './header/header-menu.model';

export interface IMasterData {
  master: IMaster;
  header: IHeaderMenu;
  menu: IMenu;
  footer: IFooter;
}

export interface IMaster {
  labels?: ILabel[] | null;
  metaData: IMasterMeta;
  scripts: IMasterScripts;
  config?: IMasterConfig;
}

export interface IMasterMeta {
  htmlLang: string;
  metaTags?: IHtmlTag[] | null;
  links?: IHtmlTag[] | null;
}

export interface IMasterScripts {
  head: IHtmlTag[];
  bodyEnd?: IHtmlTag[];
}

export interface IMasterConfig {
  enableSiteWideBanner: boolean;
}

export interface IMasterTagging {
  gtm: {
    gtmid: string;
    isEnabled: boolean;
  };
}

export enum HeaderFooterMode {
  Normal = 'NORMAL',
  Basic = 'BASIC',
  Minimal = 'MINIMAL',
}
