import { IncomingHttpHeaders } from 'http';
import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useCampaignPageQuery } from '../../../generated/graphql';
import { CampaignPage } from '../../../rs-emd-ui-modules/src/components/campaign-pages';
import { RedirectWithStatus } from '../../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';

type CampaignPageWrapperProps = {
  context?: any;
};

export const CampaignPageWrapper: FunctionComponent<CampaignPageWrapperProps> = (props: CampaignPageWrapperProps) => {
  let location = useLocation();

  //#1j4qtdp
  const brandPagePrefix = '/ourbrands/';
  const brandContentUrlPrefix = '/content/brand-page/';

  let url = location.pathname;
  if (url.startsWith(brandPagePrefix)) {
    url = url.replace(brandPagePrefix, brandContentUrlPrefix);
  }

  // check if context is populated (ssr) and get host from headers
  let host =
    (props.context && props.context.headers ? (props.context.headers as IncomingHttpHeaders).host : window.location.hostname) ?? '';

  let userAgent: string = (props.context && props.context.headers && (props.context.headers as IncomingHttpHeaders)['user-agent']) ?? '';

  let isPreview = Boolean(
    host?.indexOf('localhost') > -1 || host?.indexOf('uat') > -1 || host?.indexOf('rs-emd-dev-aws.rsdelivers.com') > -1
  );
  let isPreviewQueryString = new URLSearchParams(location.search).get('isPreview');

  try {
    if (isPreviewQueryString != null) isPreview = Boolean(JSON.parse(isPreviewQueryString.toLocaleLowerCase()));
  } catch (error) {}

  const { loading, error, data } = useCampaignPageQuery({
    variables: { url: encodeURIComponent(url), isPreview: isPreview },
    ssr: true,
  });

  if (loading) {
    return <Loader />;
  } else if (userAgent === 'ELB-HealthChecker/2.0')
    // return 200 so that the healtcheck doesn't fail
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={200} />;
  else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (!data.campaignPage.layouts || data.campaignPage.layouts.length === 0) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={404} />;
  }

  return <CampaignPage data={data.campaignPage}></CampaignPage>;
};

export default CampaignPageWrapper;
