import { AddToBasketGoogleAnalytics, BulkAddToBasketGoogleAnalytics, BulkRemoveFromBasketGoogleAnalytics } from '../generated/graphql';

export function addToCart(tagData: AddToBasketGoogleAnalytics | BulkAddToBasketGoogleAnalytics) {
  let w = window as any;

  let products: {
    name: string;
    id: string;
    price: number;
    brand: string;
    category: number;
    quantity: number;
  }[] = [];

  //  adding a product to a shopping cart.
  if (tagData.__typename === 'AddToBasketGoogleAnalytics') {
    products.push({
      name: tagData.name,
      id: tagData.id,
      price: tagData.price,
      brand: tagData.brand,
      category: tagData.category,
      quantity: tagData.quantity,
    });
  } else if (tagData.__typename === 'BulkAddToBasketGoogleAnalytics') {
    products = tagData.products.map((p) => ({
      name: p.name,
      id: p.id,
      price: p.price,
      brand: p.brand,
      category: p.category,
      quantity: p.quantity,
    }));
  }

  w.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  w.dataLayer.push({
    event: tagData.__typename === 'AddToBasketGoogleAnalytics' ? 'addToCart' : 'manualProductAdd',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        // 'add' actionFieldObject measures.
        products: products,
      },
    },
  });
}

export function removeFromCart(tagData: BulkRemoveFromBasketGoogleAnalytics) {
  let w = window as any;

  let products = tagData.products.map((p) => ({
    name: p.name,
    id: p.id,
    brand: p.brand,
    category: p.category,
    quantity: p.quantity,
  }));

  w.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  w.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        // 'add' actionFieldObject measures.
        products: products,
      },
    },
  });
}
