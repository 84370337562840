import loadable from '@loadable/component';

const CampaignPage = loadable(() => import(/* webpackChunkName: "campaign" */ './campaign-page/campaign-page-component'), {
  resolveComponent: (components) => components.CampaignPage,
});

const ButtonWidget = loadable(() => import(/* webpackChunkName: "campaign" */ './widgets/button-widget/button-widget-component'), {
  resolveComponent: (components) => components.ButtonWidget,
});

const CategoriesList = loadable(() => import(/* webpackChunkName: "campaign" */ './widgets/categories-list/categories-list-component'), {
  resolveComponent: (components) => components.CategoriesList,
});

const HeroBanner = loadable(() => import(/* webpackChunkName: "campaign" */ './widgets/hero-banner/hero-banner-component'), {
  resolveComponent: (components) => components.HeroBanner,
});

const HeroBannerCarousel = loadable(
  () => import(/* webpackChunkName: "campaign" */ './widgets/hero-banner-carousel/hero-banner-carousel-component'),
  {
    resolveComponent: (components) => components.HeroBannerCarousel,
    ssr: false,
  }
);

const ImageWidget = loadable(() => import(/* webpackChunkName: "campaign" */ './widgets/image/image-widget-component'), {
  resolveComponent: (components) => components.ImageWidget,
});

const ImageAndTextbox = loadable(
  () => import(/* webpackChunkName: "campaign" */ './widgets/image-and-textbox/image-and-textbox-component'),
  {
    resolveComponent: (components) => components.ImageAndTextbox,
  }
);

const InPageAnchor = loadable(() => import(/* webpackChunkName: "campaign" */ './widgets/in-page-anchor/in-page-anchor-component'), {
  resolveComponent: (components) => components.InPageAnchor,
});

const PopularCategories = loadable(
  () => import(/* webpackChunkName: "campaign" */ './widgets/popular-categories/popular-categories-component'),
  {
    resolveComponent: (components) => components.PopularCategories,
  }
);

const PreviouslyViewedProducts = loadable(
  () => import(/* webpackChunkName: "campaign" */ './widgets/previously-viewed-products/previously-viewed-products-component'),
  {
    resolveComponent: (components) => components.PreviouslyViewedProducts,
  }
);

const TextWidget = loadable(() => import(/* webpackChunkName: "campaign" */ './widgets/text-widget/text-widget-component'), {
  resolveComponent: (components) => components.TextWidget,
});

const Video = loadable(() => import(/* webpackChunkName: "campaign" */ './widgets/video/video-component'), {
  resolveComponent: (components) => components.Video,
});

export {
  CampaignPage,
  ButtonWidget,
  CategoriesList,
  HeroBanner,
  HeroBannerCarousel,
  ImageWidget,
  ImageAndTextbox,
  InPageAnchor,
  PopularCategories,
  PreviouslyViewedProducts,
  TextWidget,
  Video,
};
