import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useEmailSubscriptionTransactionMutation } from '../../generated/graphql';
import { SubscriptionPage } from '../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';

export const SubscribePageWrapper: FunctionComponent = () => {
  let location = useLocation();
  const token = new URLSearchParams(location.search).get('token')?.trim() ?? '';
  const email = new URLSearchParams(location.search).get('email')?.trim() ?? '';

  const [emailSubscription, { data, loading, error }] = useEmailSubscriptionTransactionMutation({
    variables: { input: { email: email, token: token, subscribe: true } },
  });

  const [pendingMutation, setPendingMutation] = useState(email !== '' && token !== '');

  useLayoutEffect(() => {
    emailSubscription();
    setPendingMutation(false);
  }, [emailSubscription]);

  if (loading || pendingMutation) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else return <SubscriptionPage data={data.emailSubscriptionTransaction} />;
};

export default SubscribePageWrapper;
